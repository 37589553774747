import { CssService } from './../../services/css.service';
import { AvailablePointsService } from './../../services/availablePoints.service';
import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { IImage } from 'ng-simple-slideshow';
import { AccountService } from 'src/app/services/account.service';
import { RedemptionService } from 'src/app/services/redemption.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { QuickCashRebateComponent } from '../rewards/quick-cash-rebate/quick-cash-rebate.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { trigger, transition, style, animate } from '@angular/animations';
import { ContentManagerService } from 'src/app/services/content-manager.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Keepalive } from '@ng-idle/keepalive';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { AuthService } from 'src/app/services/auth.service';
declare  const azigoSSO:  any;
@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('flyPosition', [
       transition('selected => left', [
         style({ transform: 'translateX(0)' }),
         animate('2s ease-out', style({ transform: 'translateX(-100%)' }))
        ]),
        transition('selected => right', [
          style({ transform: 'translateX(0)' }), animate('2s ease-out', style({ transform: 'translateX(100%)' }))
        ]),
        transition('left => selected', [
          style({ transform: 'translateX(-100%)' }), animate('2s ease-out', style({ transform: 'translateX(0)' }))
        ]),
        transition('right => selected', [
          style({ transform: 'translateX(100%)' }), animate('2s ease-out', style({ transform: 'translateX(0)' }))
        ]),
    ]),
],
})
export class HomeComponent implements OnInit, AfterViewInit {

  images: any[] = [
    this.sanitizer.bypassSecurityTrustResourceUrl(localStorage.getItem('banner__1')),
    this.sanitizer.bypassSecurityTrustResourceUrl(localStorage.getItem('banner__2')),
    this.sanitizer.bypassSecurityTrustResourceUrl(localStorage.getItem('banner__3')),
  ];
  @ViewChild('welcomeBox', {static: false}) el: ElementRef;
  @ViewChild('tiles', {static: false}) tiles: ElementRef;
  height = '438px';
  imgCashback = '../../../assets/images/CashBack.png';
  imgTravelEraser = '../../../assets/images/TravelEraser.png';
  imgLocal = '../../../assets/images/Local.png';
  imgGiftCards = '../../../assets/images/GiftCards.png';
  minHeight: string;
  arrowSize = '30px';
  showArrows = false;
  disableSwiping = false;
  autoPlay = true;
  autoPlayInterval = 15000;
  stopAutoPlayOnSlide = true;
  backgroundSize = 'cover';
  backgroundPosition = 'center center';
  backgroundRepeat = 'no-repeat';
  showDots = true;
  dotColor = '#FFF';
  showCaptions = true;
  captionColor = '#FFF';
  captionBackground = 'rgba(0, 0, 0, .35)';
  lazyLoad = false;
  hideOnNoSlides = false;
  width = '100%';
  fullscreen = false;

  decodedJWT: any;
  name: any;
  bal_id: any;
  avail_points: any;
  avail_amount: any;
  guid: any;
  travel_link: any;
  message: any;
  isRedeemable = false;
  pending_points: any;
  isNotRedeemable = false;
  expiring_points: any;
  loading = false;
  isReady = false;
  azigo_sso_key = environment.azigoSSOKey;
  azigo_end_point = environment.azigoEndpoint;
  constructor(private redemptionService: RedemptionService,
    public dialog: MatDialog, private router: Router, private cd: ChangeDetectorRef,
    private availablePointsService: AvailablePointsService,
    private idle: Idle, private keepalive: Keepalive,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private cssService: CssService ) {
    }

  ngOnInit() {
    const token = localStorage.getItem('token');
    // if (localStorage.getItem('avail_pts') === null) {
    //   document.location.reload(true);
    // }

    this.loading = true;

    const jwtData = token.split('.')[1];
    this.decodedJWT = JSON.parse(window.atob(jwtData));
    // this.name = this.decodedJWT.unique_name;
    this.bal_id = this.decodedJWT.given_name;
    const programCode = this.decodedJWT['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];

    // this.contentManagerService.getBannerImages(programCode).subscribe(data => {
    //   data.forEach(element => {
    //     this.images.push(this.sanitizer.bypassSecurityTrustResourceUrl(`data:${element.extension};base64,${element.file}`));
    //   });
    // });



    // this.avail_points = localStorage.getItem('avail_pts');
    // console.log(localStorage.getItem('avail_pts'));
    // this.avail_amount = localStorage.getItem('avail_dollars');
    // console.log(localStorage.getItem('avail_dollars'));
    // this.expiring_points = localStorage.getItem('expire_pts');
    // console.log(localStorage.getItem('expire_pts'));
    // this.pending_points = localStorage.getItem('pending_pts');
    // console.log(localStorage.getItem('pending_pts'));

    if (localStorage.getItem('avail_pts') !== null) {

      this.avail_points = localStorage.getItem('avail_pts').replace(/,/g, '');
      this.avail_amount = localStorage.getItem('avail_dollars');
      this.expiring_points = localStorage.getItem('expire_pts').replace(/,/g, '');
      this.pending_points = localStorage.getItem('pending_pts').replace(/,/g, '');
      if (this.avail_points < 10000) {
        const needed_points = 10000 - this.avail_points;
        this.message = needed_points;
        this.isRedeemable = false;
        this.isNotRedeemable = true;
      } else {
        this.message = '';
        this.isRedeemable = true;
        this.isNotRedeemable = false;
        // this.avail_points = this.avail_points.toString().split( /(?=(?:\d{3})+(?:\.|$))/g ).join( ',' );
      }
    } else {
      this.availablePointsService.setCartAvailablePoints()
        .then(result => {
          this.loading = true;
          this.avail_points = result.availablePoints.replace(/,/g, '');
          this.avail_amount = result.availableDollars;
          this.expiring_points = result.expiringPoints.replace(/,/g, '');
          this.pending_points = result.pendingPoints.replace(/,/g, '');
          if (this.avail_points < 10000) {
            const needed_points = 10000 - this.avail_points.replace(/,/g, '');
            this.message =  needed_points;
            this.isRedeemable = false;
            this.isNotRedeemable = true;
          } else {
            this.message = '';
            this.isRedeemable = true;
            this.isNotRedeemable = false;
            // this.avail_points = this.avail_points.toString().split( /(?=(?:\d{3})+(?:\.|$))/g ).join( ',' );
          }
          this.loading = false;
        }, error => { this.loading = false; });
    }

    // this.accountService.GetExpiringPoints().subscribe(data => {
    //   this.expiring_points = data;
    // });
    // this.accountService.GetPendingPoints().subscribe(data => {
    //   this.pending_points = data;
    // });
    // setTimeout(() => {
    //   this.accountService.GetAvailablePoints().subscribe(data => {
    //     this.loading = true;
    //     this.avail_points = this.format_number(data.availablePoints - this.pending_points, '', ',', '');
    //     this.avail_amount = this.format_number(data.availableAmount, '$', ',', '.');

    //     if (this.avail_points.replace(/,/g, '') < 2500) {
    //       const needed_points = 2500 - this.avail_points.replace(/,/g, '');
    //       this.message = 'YOU NEED ' + needed_points + ' MORE POINTS TO REDEEM $25 IN CASH BACK';
    //       this.isRedeemable = false;
    //       this.isNotRedeemable = true;
    //     } else {
    //       this.message = '';
    //       this.isRedeemable = true;
    //       this.isNotRedeemable = false;
    //       // this.avail_points = this.avail_points.toString().split( /(?=(?:\d{3})+(?:\.|$))/g ).join( ',' );
    //     }
    //     this.loading = false;
    //   }, error => { this.loading = false; }); }, 2675);

    // this.router.navigateByUrl('home', { skipLocationChange: true }).then(() =>
    // this.router.navigate(['home']));

    this.loading = false;
    this.isReady = true;
  }

  ngAfterViewInit(): void {
    if (this.cssService.isIE()) {
      const comp = this;
      setTimeout(function() {
        comp.cssService.reloadHomePageIE();
        comp.cssService.styleHomeWelcomeBoxIE(this.el);
        comp.cssService.styleHomeTiles(this.tiles);
      }, 1000);
    }
  }

  openQuickCashRebate() {
     this.router.navigate(['rewards/cash_rebate']);
  }
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
  format_number(number: number, prefix, thousand_sep, decimal_sep) {
    if (number < 0) {
      prefix = '-' + prefix;
    }
    const thousand_separator =  thousand_sep || ',';
    const decimal_separator = decimal_sep || '.';
    const regex		= new RegExp('[^' + decimal_separator + '\\d]', 'g');
    const number_string = number.toString().replace(regex, '').toString();
    const split = number_string.split(decimal_separator);
    const rest = split[0].length % 3;
    let result = split[0].substr(0, rest);
    const thousands = split[0].substr(rest).match(/\d{3}/g);

    if (thousands) {
      const separator = rest ? thousand_separator : '';
      result += separator + thousands.join(thousand_separator);
    }
    result = split[1] !== undefined ? result + decimal_separator + split[1] : result;
    return prefix === undefined ? result : (result ? prefix + result : '');
  }
  redirectToAzigo() {
    azigoSSO(this.azigo_sso_key, this.azigo_end_point, this.bal_id);
  }
  isIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const trident = ua.indexOf('Trident/');

    return (msie > 0 || trident > 0);
  }
}
