import { environment } from './../../../../environments/environment.prod';
import { Keepalive } from '@ng-idle/keepalive';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { AvailablePointsService } from './../../../services/availablePoints.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ResetPassword } from 'src/app/models/ResetPassword';
import { MatSnackBarConfig, MatSnackBar, MatDialogConfig, MatDialog } from '@angular/material';
import { ContentManagerService } from 'src/app/services/content-manager.service';
import { TimeOutComponent } from '../../time-out/time-out.component';

@Component({
  selector: 'app-auth-reset',
  templateUrl: './auth-reset.component.html',
  styleUrls: ['./auth-reset.component.css']
})
export class AuthResetComponent implements OnInit {

  id = '';
  passwordForm: FormGroup;
  isError = false;
  errors: any;
  loading = false;
  actionButtonLabel = 'Close';
  action = true;

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService,
        private snackBar: MatSnackBar, private availablePointsService: AvailablePointsService,
        private contentManagerService: ContentManagerService, private idle: Idle, private keepalive: Keepalive,
        private dialog: MatDialog) {
    this.route.params.subscribe(params => this.id = params['id']);
   }

  ngOnInit() {
    this.passwordForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      passwordConfirmation: new FormControl('', [Validators.required, Validators.minLength(8)])
    });
  }

  resetPassword(formValues: any) {
    this.loading = true;
    const reset: ResetPassword = {
      token: this.id,
      password: formValues.password,
      passwordConfirmation: formValues.passwordConfirmation
    };

    this.authService.Recover3(reset, environment.client_id).subscribe(data => {
      localStorage.clear();
      localStorage.setItem('token', data.token);
      this.availablePointsService.setCartAvailablePoints()
        .then(() => {
          this.contentManagerService.getBannerImagesPathPromise()
            .catch(() => { return; })
            .then(() => {
              this.contentManagerService.getDocumentsPathPromise()
                .catch(() => { return; })
                .then(() => {
                  this.contentManagerService.getLogoImagePathToPromise()
                  .catch(() => { return; })
                  .then(() => {
                    this.contentManagerService.getCppsToPromise()
                      .catch(() =>  { return; })
                      .then(() => {
                        this.contentManagerService.getProgramNameToPromise()
                          .catch(() => { return; })
                          .then(() => {
                            this.router.navigate(['home']);
                            this.loading = false;
                          }, () => {
                            this.router.navigate(['home']);
                            this.loading = false;
                          });
                        });
                    });
                  });
            });
            this.timeout();
        }, err => {
          const configError = new MatSnackBarConfig();
          configError.verticalPosition = 'top';
          configError.duration = 5000;
          configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
          const key = Object.keys(err.error)[0];
          this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
            err.error.errors : err.error !== null && err.error !== undefined ?
            err.error[key][0].length > 1 ? err.error[key][0]
            : err.error : 'Whoops! Something went wrong',
          this.action ? this.actionButtonLabel : undefined, configError);
        });
      // this.router.navigate(['home']);
    }, err => {
      const configError = new MatSnackBarConfig();
      configError.verticalPosition = 'top';
      configError.duration = 5000;
      configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
      const key = Object.keys(err.error)[0];
      this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
        err.error.errors : err.error !== null && err.error !== undefined ?
        err.error[key][0].length > 1 ? err.error[key][0]
        : err.error : 'Whoops! Something went wrong',
      this.action ? this.actionButtonLabel : undefined, configError);
    });
  }

  timeout() {
    // sets an idle timeout of 900 seconds, for testing purposes.
    this.idle.setIdle(900);
    // sets a timeout period of 900 seconds. after 60 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      // console.log(this.idleState);
    });
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      // console.log(this.idleState);
      this.timedOut = true;
    });
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
      this.openDialog();
      // console.log(this.idleState);
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      // console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => {
      this.lastPing = new Date();
      // console.log('this last ping!');
    });

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    // console.log('reset');
    this.timedOut = false;
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;

    const dialogRef = this.dialog.open(TimeOutComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {
      this.idle.onIdleEnd.subscribe(() => {
        this.idleState = 'No longer idle.';
        // console.log(this.idleState);
      });
    });
  }

}
