import { AvailablePointsService } from './../../../services/availablePoints.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { PointsService } from 'src/app/services/points.service';
import { GiftPoints } from 'src/app/models/gift_points';


@Component({
  selector: 'app-gift-points',
  templateUrl: './gift-points.component.html',
  styleUrls: ['./gift-points.component.css']
})
export class GiftPointsComponent implements OnInit {
  giftPointsForm: FormGroup;
  acountverificationForm: FormGroup;
  actionButtonLabel = 'Close';
  action = true;
  setAutoHide = true;
  autoHide = 3000;
  addExtraClass = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  errorText: string;
  avail_points: any;
  gift_points_avail_points: any;
  expire_date: any;
  remaining_points: any;
  decodedJWT: any;
  bal_id: any;
  giftee_msg: string;
  avail_points2: any;
  pending_points: any;
  giftee_info: any;
  loading = false;
  token = localStorage.getItem('token');
  jwt = this.parseJWT(this.token);
  program_code = this.jwt['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
  disabled = true;
  // isVerified = false;

  constructor(private accountService: AccountService, private pointsService: PointsService, public snackBar: MatSnackBar,
    private availablePointsService: AvailablePointsService, private router: Router) { }

  ngOnInit() {
    this.router.navigate(['home']);//BW-8142
    if (localStorage.getItem('expire_pts') !== null && localStorage.getItem('avail_pts') !== null) {
      this.pending_points = +(localStorage.getItem('expire_pts'));
      this.avail_points = localStorage.getItem('avail_pts');
      this.avail_points = this.avail_points.replace(/,/g, '');
      this.remaining_points = this.avail_points;
      this.avail_points2 = this.avail_points;
    } else {
      this.accountService.GetPendingPoints().subscribe(data => {
        this.pending_points = data;
      });
      this.accountService.GetAvailablePoints().subscribe(data => {
        this.avail_points = data.availablePoints - this.pending_points;
        this.avail_points = this.format_number(this.avail_points, '', ',', '');
        this.remaining_points = this.avail_points;
        this.avail_points2 = this.avail_points;
      });
    }
       this.accountService.GetGiftPointsAvailablePoints().subscribe(data => {
       this.gift_points_avail_points = data;
       console.log('avail: ');
       console.log(this.avail_points);
       console.log('gift avail: ');
       console.log(this.gift_points_avail_points);
       this.remaining_points = this.gift_points_avail_points;
       this.avail_points2 = this.gift_points_avail_points;
       console.log('assigned avail: ');
       console.log(this.avail_points2);
     });
    this.giftPointsForm = new FormGroup({
      acct_no: new FormControl('', [Validators.required]),
      recipient: new FormControl(),
      recipient_email: new FormControl(),
      points: new FormControl('', [Validators.required]),
      message: new FormControl()
    });

    this.acountverificationForm = new FormGroup({
      acct_no: new FormControl('', [Validators.required, Validators.minLength(7), Validators.maxLength(20),
        Validators.pattern('^[0-9]+$')]),
    });

    this.giftPointsForm.controls['recipient'].disable();
    this.giftPointsForm.controls['recipient_email'].disable();

    const expire = moment().add(1, 'year').toDate();

    this.expire_date = new Date(expire.getFullYear(), expire.getMonth(), expire.getDate(), 23, 59, 59 );
    if (this.avail_points2 < 0) {
      this.giftPointsForm.controls['points'].disable();
      this.loading = false;
      const configError = new MatSnackBarConfig();
      configError.verticalPosition = 'top';
      configError.duration = 5000;
      configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
      this.snackBar.open('Sorry, you do not have enough points to transfer.', this.actionButtonLabel, configError);
    }
    // this.remaining_points = this.remaining_points.toString().split( /(?=(?:\d{3})+(?:\.|$))/g ).join( ',' );
    // this.avail_points2 = this.avail_points2.toString().split( /(?=(?:\d{3})+(?:\.|$))/g ).join( ',' );
  }
  parseJWT(token: any) {
    token = localStorage.getItem('token');
    const jwtData = token.split('.')[1];
    return JSON.parse(window.atob(jwtData));
  }
  onChanges() {
    if (this.giftPointsForm.value.points < 1) {
      this.loading = false;
      const configError = new MatSnackBarConfig();
      configError.verticalPosition = 'top';
      configError.duration = 5000;
      configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
      this.snackBar.open('Points to gift must not be less than one.', this.actionButtonLabel, configError);
    } else {
    this.remaining_points = this.avail_points2 - this.giftPointsForm.value.points; }
    if (this.giftPointsForm.value.points > this.avail_points2) {
      this.loading = false;
      const configError = new MatSnackBarConfig();
      configError.verticalPosition = 'top';
      configError.duration = 5000;
      configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
      this.snackBar.open('Points to gift must be less than or equal to your available Points.', this.actionButtonLabel, configError); }
  }
  private _createConfig() {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    return config;
  }
  verify() {
    this.loading = true;
    const reward_no = this.acountverificationForm.value.acct_no;

    this.pointsService.GetGiftee(reward_no).subscribe(data => {
      this.loading = false;
      // this.isVerified = true;
      this.giftPointsForm.patchValue({
        acct_no: reward_no,
        recipient: data.primaryName,
        recipient_email: data.email
      });
      this.giftee_info = data;
    }, err => {
      this.loading = false;
      const configError = new MatSnackBarConfig();
      configError.verticalPosition = 'top';
      configError.duration = 5000;
      configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
      const key = Object.keys(err.error)[0];
      this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
        err.error.errors : err.error !== null && err.error !== undefined ?
        err.error[key][0].length > 1 ? err.error[key][0]
        : err.error : 'Whoops! Something went wrong, please try again',
      this.action ? this.actionButtonLabel : undefined, configError);
      });
  }
  submit() {
    this.loading = true;
    const config = this._createConfig();
    if (this.giftPointsForm.value.points > 0 && this.giftPointsForm.value.points <= this.avail_points2) {
    const gift_points: GiftPoints = {
      gifteeId: this.giftee_info.id,
      points: this.giftPointsForm.value.points,
      programCode: this.program_code,
      rewardCode: 'GIFTPTS',
      message: this.giftPointsForm.value.message
    };
    this.pointsService.SubmitGiftPoints(gift_points).subscribe(data => {
      this.availablePointsService.setCartAvailablePoints()
        .then(result => {
          this.avail_points = result.availablePoints;
          console.log(this.avail_points);
          this.router.navigateByUrl('home', { skipLocationChange: true }).then(() =>
          this.router.navigate(['points/gift_points']));
        });
      this.loading = false;
      this.snackBar.open('You have successfully gifted ' + gift_points.points + ' points to ' + this.giftee_info.primaryName, '', config);
    }, err => {
      this.loading = false;
      const configError = new MatSnackBarConfig();
      configError.verticalPosition = 'top';
      configError.duration = 5000;
      configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
      const key = Object.keys(err.error)[0];
      this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
        err.error.errors : err.error !== null && err.error !== undefined ?
        err.error[key][0].length > 1 ? err.error[key][0]
        : err.error : 'Whoops! Something went wrong, please try again',
      this.action ? this.actionButtonLabel : undefined, configError);
    });
    } else {
      this.loading = false;
      const configError = new MatSnackBarConfig();
      configError.verticalPosition = 'top';
      configError.duration = 5000;
      configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
      this.snackBar.open('Points to gift must be less than or equal to your available Points', this.actionButtonLabel, configError); }
  }

  format_number(number: number, prefix, thousand_sep, decimal_sep) {
    if (number < 0) {
      prefix = '-' + prefix;
    }
    const thousand_separator =  thousand_sep || ',';
    const decimal_separator = decimal_sep || '.';
    const regex		= new RegExp('[^' + decimal_separator + '\\d]', 'g');
    const number_string = number.toString().replace(regex, '').toString();
    const split = number_string.split(decimal_separator);
    const rest = split[0].length % 3;
    let result = split[0].substr(0, rest);
    const thousands = split[0].substr(rest).match(/\d{3}/g);

    if (thousands) {
      const separator = rest ? thousand_separator : '';
      result += separator + thousands.join(thousand_separator);
    }
    result = split[1] !== undefined ? result + decimal_separator + split[1] : result;
    return prefix === undefined ? result : (result ? prefix + result : '');
  }

}
