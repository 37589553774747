import { CssService } from './../../../services/css.service';
import { environment } from './../../../../environments/environment.prod';
import { AvailablePointsService } from './../../../services/availablePoints.service';
import { AccountService } from 'src/app/services/account.service';
import { MatSnackBar, MatSnackBarConfig, MatDialog, MatDialogConfig } from '@angular/material';
import { ErrorSharingService } from './../../../services/error-sharing.service';
import { Component, OnInit } from '@angular/core';
import { IImage } from 'ng-simple-slideshow';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Authorize } from 'src/app/models/authorize';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { ContentManagerService } from 'src/app/services/content-manager.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { TimeOutComponent } from '../../time-out/time-out.component';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [
        trigger('flyPosition', [
           transition('selected => left', [
             style({ transform: 'translateX(0)' }),
             animate('2s ease-out', style({ transform: 'translateX(-100%)' }))
            ]),
            transition('selected => right', [
              style({ transform: 'translateX(0)' }), animate('2s ease-out', style({ transform: 'translateX(100%)' }))
            ]),
            transition('left => selected', [
              style({ transform: 'translateX(-100%)' }), animate('2s ease-out', style({ transform: 'translateX(0)' }))
            ]),
            transition('right => selected', [
              style({ transform: 'translateX(100%)' }), animate('2s ease-out', style({ transform: 'translateX(0)' }))
            ]),
        ]),
    ],
})
export class LoginComponent implements OnInit {

  height = '438px';
  minHeight: string;
  arrowSize = '30px';
  showArrows = false;
  disableSwiping = false;
  autoPlay = true;
  autoPlayInterval = 3333;
  stopAutoPlayOnSlide = true;
  backgroundSize = 'cover';
  backgroundPosition = 'center center';
  backgroundRepeat = 'no-repeat';
  showDots = true;
  dotColor = '#FFF';
  showCaptions = true;
  captionColor = '#FFF';
  captionBackground = 'rgba(0, 0, 0, .35)';
  lazyLoad = false;
  hideOnNoSlides = false;
  error = null;
  width = '100%';
  fullscreen = false;
  login_form: FormGroup;
  loading = false;
  actionButtonLabel = 'Close';
  action = true;
  images: string[] = [
    './../../assets/images/slideshow/1.jpg',
    './../../assets/images/slideshow/2.jpg',
    './../../assets/images/slideshow/3.jpg'
  ];

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  constructor(
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private availablePointsService: AvailablePointsService,
    private idle: Idle, private keepalive: Keepalive,
    private contentManagerService: ContentManagerService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private cssService: CssService
  ) {
    this.route.params.subscribe(params => {
      if (params['error?'] !== undefined && params['error?'] !== null) { this.error = params['error?']; }
});

  }

  ngOnInit() {
    this.login_form = new FormGroup({
      username: new FormControl(
        '',
        [
          Validators.required
        ],
      ),
      password: new FormControl (
        '',
        [
          Validators.required,
          Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z].{8,}')
        ],
      )
    });
    if (this.error !== undefined && this.error !== null) {
      this.loading = false;
      const config = new MatSnackBarConfig();
      config.verticalPosition = 'top';
      config.duration = 10000;
      config.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
      this.snackBar.open('Sorry, account not found in our system.', this.action ? this.actionButtonLabel : undefined, config);
    }
  }

  login() {
    this.loading = true;
    localStorage.clear();
    localStorage.removeItem('token');
    const auth: Authorize = { username: this.login_form.get('username').value, password: this.login_form.get('password').value };
    this.authService.Login(auth, environment.client_id)
      .subscribe(data => {
        // this.loading = false;
        localStorage.setItem('token', data.token);
        localStorage.setItem('Demo',  'false');

        // setTimeout(() => { this.availablePoints.setSessionAvailablePoints(); }, 1000);
        // this.router.navigate(['home']);

        this.availablePointsService.setCartAvailablePoints()
        .then(() => {
          this.contentManagerService.getBannerImagesPathPromise()
            .catch(() => { return; })
            .then(() => {
              this.contentManagerService.getDocumentsPathPromise()
                .catch(() => { return; })
                .then(() => {
                  this.contentManagerService.getLogoImagePathToPromise()
                  .catch(() => { return; })
                  .then(() => {
                    this.contentManagerService.getCppsToPromise()
                      .catch(() =>  { return; })
                      .then(() => {
                        this.contentManagerService.getProgramNameToPromise()
                          .catch(() => { return; })
                          .then(() => {
                            this.router.navigate(['home']);
                            this.loading = false;
                          }, () => {
                            this.router.navigate(['home']);
                            this.loading = false;
                          });
                        });
                    });
                  });
            });
            this.timeout();
        }, error => {
          this.loading = false;
          const config = new MatSnackBarConfig();
          config.verticalPosition = 'top';
          config.duration = 5000;
          config.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
          const key = Object.keys(error.error)[0];
          this.snackBar.open(error.error.errors !== null && error.error.errors !== undefined ?
            error.error.errors : error.error !== null && error.error !== undefined ?
            error.error[key][0].length > 1 ? error.error[key][0]
            : error.error : 'Whoops! Something went wrong',
          this.action ? this.actionButtonLabel : undefined, config);
        });
      },
      err => {
        this.loading = false;
        const config = new MatSnackBarConfig();
        config.verticalPosition = 'top';
        config.duration = 5000;
        config.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
        const key = Object.keys(err.error)[0];
        this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
          err.error.errors : err.error !== null && err.error !== undefined ?
          err.error[key][0].length > 1 ? err.error[key][0]
          : err.error : 'Whoops! Something went wrong',
        this.action ? this.actionButtonLabel : undefined, config);
      });
  }

  timeout() {
    // sets an idle timeout of 900 seconds, for testing purposes.
    this.idle.setIdle(900);
    // sets a timeout period of 900 seconds. after 60 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      // console.log(this.idleState);
    });
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      // console.log(this.idleState);
      this.timedOut = true;
    });
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
      this.openDialog();
      // console.log(this.idleState);
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      // console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => {
      this.lastPing = new Date();
      // console.log('this last ping!');
    });

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    // console.log('reset');
    this.timedOut = false;
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;

    const dialogRef = this.dialog.open(TimeOutComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {
      this.idle.onIdleEnd.subscribe(() => {
        this.idleState = 'No longer idle.';
        // console.log(this.idleState);
      });
    });
  }
}
