import { Component, OnInit, isDevMode } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RewardsService } from 'src/app/services/rewards.service';
import { Product } from 'src/app/models/product';
import { environment } from 'src/environments/environment';
import { EgcParams } from './../../../models/egc-params';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  token = localStorage.getItem('token');
  jwt = this.parseJWT(this.token);
  program_code = this.jwt['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
  avail_points: any;
  id: any;
  product_info: Product;
  pending_points: any;
  eCertImageCode: string;
  img_url: any;
  isGC = false;
  isEC = false;
  constructor(private accountService: AccountService, private rewardService: RewardsService, private router: Router,
    private route: ActivatedRoute, ) {
    this.route.params.subscribe(params => (this.id = params['id']));
   }

  ngOnInit() {
    this.router.navigate(['home']);//BW-8142
    if (localStorage.getItem('expire_pts') !== null && localStorage.getItem('avail_pts') !== null) {
      this.pending_points = +(localStorage.getItem('expire_pts'));
      this.avail_points = localStorage.getItem('avail_pts');
    } else {
      this.accountService.GetPendingPoints().subscribe(data => {
        this.pending_points = data;
      });
      this.accountService.GetAvailablePoints().subscribe(data => {
        this.avail_points = data.availablePoints - this.pending_points;
        this.avail_points = this.format_number(this.avail_points, '', ',', '');
      });
    }
    if (isNaN(this.id)) {
      const params = new EgcParams();
      params.clientId = environment.client_id;
      params.programCode = this.program_code;
      params.rewardCategory = 'ESVS';
      params.rewardCode = this.id;
      params.rewardType = 'EC';
      this.rewardService.GetProductEc(params).subscribe(data => {
        this.product_info = data;
        this.eCertImageCode = this.product_info.rewardCode.replace(/\d+$/, '');
        this.img_url = this.GetImageURLEC(this.eCertImageCode.substring(0, this.eCertImageCode.length - 1) , 'SVS');
        this.product_info.imgUrl = this.img_url;
        if ((this.product_info.type != null || this.product_info.description !== undefined) && this.product_info.type === 'GC' ) {
          this.isGC = true;
        }
        if ((this.product_info.type != null || this.product_info.description !== undefined) && this.product_info.type === 'EC' ) {
          this.isEC = true;
        }
        if (this.product_info.description == null || this.product_info.description === undefined) {
          this.product_info.description = '';
        }
      });
    } else {
    this.rewardService.GetProductById(this.id).subscribe(data => {
      this.product_info = data;
      this.img_url = this.GetImageURL(this.product_info.rewardCode, '150');
      this.product_info.imgUrl = this.img_url;
      if ((this.product_info.type != null || this.product_info.description !== undefined) && this.product_info.type === 'GC' ) {
        this.isGC = true;
      }
      if ((this.product_info.type != null || this.product_info.description !== undefined) && this.product_info.type === 'EC' ) {
        this.isEC = true;
      }
      if (this.product_info.description == null || this.product_info.description === undefined) {
        this.product_info.description = '';
      }
    });
  }
  }
  GetImageURL(reward_code: any, size: any) {
    if (isDevMode()) {
      // let useSize = '150';
      //  useSize = size;
      // return '../../../assets/images/RewardImages/150/' + reward_code + '.jpg';
    }
    if (environment.baseCors.includes('bwloyalty')) {
      return `${environment.baseCors}RewardImages/150/${reward_code}.jpg`.replace('www.', '');
    } else {
      return `${environment.baseCors}RewardImages/150/${reward_code}.jpg`;
    }
  }
  GetImageURLEC(reward_code: any, size: any) {
    if (isDevMode()) {
      // let useSize = '150';
      //  useSize = size;
      // return '../../../assets/images/RewardImages/150/' + reward_code + '.jpg';
    }
    if (environment.baseCors.includes('bwloyalty')) {
      return `${environment.baseCors}RewardImages/SVS/${reward_code}.jpg`.replace('www.', '');
    } else {
      return `${environment.baseCors}RewardImages/SVS/${reward_code}.jpg`;
    }
  }
  format_number(number: number, prefix, thousand_sep, decimal_sep) {
    if (number < 0) {
      prefix = '-' + prefix;
    }
    const thousand_separator =  thousand_sep || ',';
    const decimal_separator = decimal_sep || '.';
    const regex		= new RegExp('[^' + decimal_separator + '\\d]', 'g');
    const number_string = number.toString().replace(regex, '').toString();
    const split = number_string.split(decimal_separator);
    const rest = split[0].length % 3;
    let result = split[0].substr(0, rest);
    const thousands = split[0].substr(rest).match(/\d{3}/g);

    if (thousands) {
      const separator = rest ? thousand_separator : '';
      result += separator + thousands.join(thousand_separator);
    }
    result = split[1] !== undefined ? result + decimal_separator + split[1] : result;
    return prefix === undefined ? result : (result ? prefix + result : '');
  }
  parseJWT(token: any) {
    token = localStorage.getItem('token');
    const jwtData = token.split('.')[1];
    return JSON.parse(window.atob(jwtData));
  }
}
