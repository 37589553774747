import { AvailablePointsService } from './../../../services/availablePoints.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatStepper, MatSnackBarConfig, MatSnackBar } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { RedemptionService } from 'src/app/services/redemption.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-quick-cash-rebate',
  templateUrl: './quick-cash-rebate.component.html',
  styleUrls: ['./quick-cash-rebate.component.css']
})
export class QuickCashRebateComponent implements OnInit {
  infoFormGroup: FormGroup;
  accounts: any;
  points: any;
  avail_amount: any;
  errorText: string;
  loading = false;
  actionButtonLabel = 'Close';
  action = true;

  constructor(private dialogRef: MatDialogRef<QuickCashRebateComponent>, private accountService: AccountService,
    private redemptionService: RedemptionService, private snackBar: MatSnackBar,
    private availablePointsService: AvailablePointsService, private router: Router) { }

  ngOnInit() {
    this.router.navigate(['home']);//BW-8142
    this.accountService.GetAvailablePoints().subscribe(data => {
      this.points = data.availablePoints;
      this.avail_amount = this.format_number(data.availableAmount, '$', ',', '.');

      this.infoFormGroup.patchValue({
        amount: this.avail_amount
      });
    });
    this.accountService.GetAccount().subscribe(data => {
      this.accounts = data.accountNumber;
    });
    this.infoFormGroup = new FormGroup({
      amount: new FormControl('', [Validators.required, Validators.min(25)])
    });
    this.points = (this.avail_amount * 100) + 275;
  }
  onChanges(value: any): void {
    this.delay(2000).then(() => {
      // tslint:disable-next-line:radix
      const amount = +(this.infoFormGroup.controls['amount'].value.replace('$', '').replace(/,/g, ''));
      this.infoFormGroup.valueChanges.subscribe(val => {
        if (amount > 0) {
          this.points = Math.ceil((amount / .01)) + 175;
        } else {
          this.points = 0;
        }

      });

      const amountText = this.format_number(amount, '$', ',', '.');
      this.infoFormGroup.patchValue({
        amount: amountText
      });
    });

  }
  submit(stepper: MatStepper) {
    this.loading = true;
    const redemption = {
      rewardCode: 'CASHCC',
      pointsRequired: this.points,
      rewardCost: +this.infoFormGroup.controls['amount'].value.replace('$', '').replace(/,/g, '').replace('.', '')
    };
    this.redemptionService.PostCashBack(redemption).subscribe(() => {
      this.loading = false;
      this.availablePointsService.setSessionAvailablePoints();
      stepper.next();
     }, err => {
         this.loading = false;
         const config = new MatSnackBarConfig();
        config.verticalPosition = 'top';
        config.duration = 5000;
        config.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
        const key = Object.keys(err.error)[0];
        this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
          err.error.errors : err.error !== null && err.error !== undefined ?
          err.error[key][0].length > 1 ? err.error[key][0]
          : err.error : 'Whoops! Something went wrong',
        this.action ? this.actionButtonLabel : undefined, config);
     });
  }
  close() {
    this.dialogRef.close();
  }

  format_number(number: number, prefix, thousand_sep, decimal_sep) {
    if (number < 0) {
      prefix = '-' + prefix;
    }
    const thousand_separator =  thousand_sep || ',';
    const decimal_separator = decimal_sep || '.';
    const regex		= new RegExp('[^' + decimal_separator + '\\d]', 'g');
    let number_string = number.toFixed(2);
    number_string = number_string.replace(regex, '').toString();
    const split = number_string.split(decimal_separator);
    const rest = split[0].length % 3;
    let result = split[0].substr(0, rest);
    const thousands = split[0].substr(rest).match(/\d{3}/g);

    if (thousands) {
      const separator = rest ? thousand_separator : '';
      result += separator + thousands.join(thousand_separator);
    }
    result = split[1] !== undefined ? result + decimal_separator + split[1] : result;
    return prefix === undefined ? result : (result ? prefix + result : '');
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}

}
