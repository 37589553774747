export const environment = {

  /* PROD CONFIG */
   baseApiUrl: 'https://redeembankrewards.com/api/',
   baseCors: 'https://redeembankrewards.com/',
   montroseUrl: 'https://saml2.breakawayloyalty.com/MontroseSSO.aspx?SessionID=',
   hindmerchUrl: 'https://hinda.breakawayloyalty.com/DefaultSSO.aspx?sso=',
   contentManagerAPI: 'https://contentmanager.breakawayloyalty.com/api/',
   client_id: '202001',
   azigoEndpoint: 'https://gep.partnersmall.com/sso?hash=',
   azigoSSOKey: 'o25626604d86cd8fcf4ff37d5370f32c',
   production: true
  /* END PROD CONFIG */
 
  /* TEST CONFIG */
  // baseApiUrl: 'https://glacier.bwloyalty.com/api/',
  // baseCors: 'https://glacier.bwloyalty.com/',
  // montroseUrl: 'https://testsaml2.bwloyalty.com/MontroseSSO.aspx?SessionID=',
  // contentManagerAPI: 'https://contentmanager.bwloyalty.com/api/',
  // hindmerchUrl: 'https://hinda.bwloyalty.com/DefaultSSO.aspx?sso=',
  // client_id: '202001',
  // azigoEndpoint: 'https://gep.partnersmall.com/sso?hash=',
  // azigoSSOKey: 'o25626604d86cd8fcf4ff37d5370f32c',
  // production: true
   /* END TEST CONFIG */
};
