import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { RewardsService } from 'src/app/services/rewards.service';
import { RedemptionService } from 'src/app/services/redemption.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material';
import { FormControl, FormGroup } from '@angular/forms';
import { AvailablePointsService } from 'src/app/services/availablePoints.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig, MatRadioChange } from '@angular/material';
@Component({
  selector: 'app-cart',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.css']
})
export class ShippingComponent implements OnInit {
  token = localStorage.getItem('token');
  jwt = this.parseJWT(this.token);
  program_code = this.jwt['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
  avail_points: any;
  total_avail_points: any;
  cart = [] = [];
  message: string;
  displayedColumns: string[] = ['title', 'quantity', 'points', 'actions'];
  dataSource = new MatTableDataSource();
  ifItems: boolean;
  chosenShipping = 'USPSSCL';
  isDisplayed = false;
  shipping: any = [];
  chosenFeeWithPoints = 'CSH';
  shippingChoice: string;
  total: any;
  form: FormGroup;
  pending_points: any;
  loading = false;
  isChecked = false;
  setAutoHide = true;
  autoHide = 3000;
  actionButtonLabel = 'Close';
  action = true;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private accountService: AccountService, private redemptionService: RedemptionService, private router: Router,
    public snackBar: MatSnackBar, private availablePointsService: AvailablePointsService, private rewardsService: RewardsService) { }

  ngOnInit() {
    this.router.navigate(['home']);//BW-8142
    this.loading = true;
    this.redemptionService.DeleteShipping().subscribe(data => {
      this.loading = false;
      this.availablePointsService.setCartAvailablePoints()
        .then(result => {
          this.avail_points = result.availablePoints;
        });
    });
    if (localStorage.getItem('expire_pts') !== null && localStorage.getItem('avail_pts') !== null) {
      this.pending_points = +(localStorage.getItem('expire_pts'));
      this.avail_points = localStorage.getItem('avail_pts');
    } else {
      this.accountService.GetPendingPoints().subscribe(data => {
        this.pending_points = data;
      });
      this.accountService.GetAvailablePoints().subscribe(data => {
        this.avail_points = data.availablePoints - this.pending_points;
        this.avail_points = this.format_number(this.avail_points, '', ',', '');
        this.total_avail_points = data.availablePoints;
      });
    }
    this.redemptionService.GetCartItems().subscribe(data => {
      if (data != null) {
        this.rewardsService.GetGCShippingFeesBusiness().subscribe(d => {
          if (d != null) {
            this.shipping = d;
          }
        });
      }
    });
  }
  onChecked($event) {
    this.isChecked = $event.checked;
  }
  redeemAllItems() {
    const config = this._createConfig();
    if (this.avail_points.replace(/,/g, '') >= 0) {
    this.loading = true;
    if (!this.isChecked) {
      this.loading = false;
      const configError = new MatSnackBarConfig();
      configError.verticalPosition = 'top';
      configError.duration = 5000;
      configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
      this.snackBar.open('You must accept the Shipping Disclaimer by clicking the checkbox next to it.',
       this.actionButtonLabel, configError);
    } else {
      if (this.chosenShipping !== undefined && this.chosenFeeWithPoints !== undefined) {
        this.shippingChoice = this.chosenShipping;
        if (this.chosenShipping !== 'USPSSCL') {
            this.shippingChoice += this.chosenFeeWithPoints === 'PTS' ? 'PTS' : 'CSH';
        }
        const shipping_choice = {
          rewardCode: this.shippingChoice
        };
        this.redemptionService.PostShippingChoice(shipping_choice).subscribe(data => {
          this.availablePointsService.setSessionAvailablePoints();
           this.availablePointsService
             .setCartAvailablePoints()
             .then(result => {
               this.avail_points = result.availablePoints;
               console.log(this.avail_points);
             });
             this.redemptionService.RedeemAllItems().subscribe(info => {
              this.loading = false;
              this.snackBar.open('All items have been successfully redeemed.', '', config);
              this.router.navigateByUrl('home', { skipLocationChange: true }).then(() =>
              this.router.navigate(['cart']));
            }, err => {
             this.loading = false;
             this.redemptionService.DeleteShipping().subscribe(data => {
               this.loading = false;
               this.availablePointsService.setCartAvailablePoints()
                 .then(result => {
                   this.avail_points = result.availablePoints;
                 });
             });
             const configError = new MatSnackBarConfig();
             configError.verticalPosition = 'top';
             configError.duration = 5000;
             configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
             const key = Object.keys(err.error)[0];
             this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
               err.error.errors : err.error !== null && err.error !== undefined ?
               err.error[key][0].length > 1 ? err.error[key][0]
               : err.error : 'Whoops! Something went wrong',
             this.action ? this.actionButtonLabel : undefined, configError);
            });
         }, err => {
          this.loading = false;
          this.redemptionService.DeleteShipping().subscribe(dt => {
            this.loading = false;
            this.availablePointsService.setCartAvailablePoints()
              .then(result => {
                this.avail_points = result.availablePoints;
              });
          });
          const configError = new MatSnackBarConfig();
          configError.verticalPosition = 'top';
          configError.duration = 5000;
          configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
          const key = Object.keys(err.error)[0];
          this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
            err.error.errors : err.error !== null && err.error !== undefined ?
            err.error[key][0].length > 1 ? err.error[key][0]
            : err.error : 'Whoops! Something went wrong',
          this.action ? this.actionButtonLabel : undefined, configError);
         }
       );    
    }
  }
  } else {
    this.loading = false;
    const configError = new MatSnackBarConfig();
    configError.verticalPosition = 'top';
    configError.duration = 5000;
    configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
    this.snackBar.open('Points to redeem must be less than or equal to your available Points.', this.actionButtonLabel, configError);
  }
  }
  onItemChange(mrChange: MatRadioChange) {
    this.chosenShipping = mrChange.value;
    if (this.chosenShipping === 'USPSSCL')
    { this.isDisplayed = false; } 
    else { this.isDisplayed = true; }
  }
  onSelectionChange(mrChange: MatRadioChange) {
    this.chosenFeeWithPoints = mrChange.value;
  }
  format_number(number: number, prefix, thousand_sep, decimal_sep) {
    if (number < 0) {
      prefix = '-' + prefix;
    }
    const thousand_separator =  thousand_sep || ',';
    const decimal_separator = decimal_sep || '.';
    const regex		= new RegExp('[^' + decimal_separator + '\\d]', 'g');
    const number_string = number.toString().replace(regex, '').toString();
    const split = number_string.split(decimal_separator);
    const rest = split[0].length % 3;
    let result = split[0].substr(0, rest);
    const thousands = split[0].substr(rest).match(/\d{3}/g);

    if (thousands) {
      const separator = rest ? thousand_separator : '';
      result += separator + thousands.join(thousand_separator);
    }
    result = split[1] !== undefined ? result + decimal_separator + split[1] : result;
    return prefix === undefined ? result : (result ? prefix + result : '');
  }
  parseJWT(token: any) {
    token = localStorage.getItem('token');
    const jwtData = token.split('.')[1];
    return JSON.parse(window.atob(jwtData));
  }
  private _createConfig() {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    return config;
  }
}
