import { AvailablePointsService } from 'src/app/services/availablePoints.service';
import { Component, HostListener, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import { Subject } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router, NavigationEnd } from '@angular/router';
import { TimeOutComponent } from './components/time-out/time-out.component';
import { ContentManagerService } from './services/content-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  currentPath: any;
  private themeWrapper = document.querySelector('body');

  // userActivity;
  // userInactive: Subject<any> = new Subject();

  constructor(private dialog: MatDialog, private idle: Idle, private keepalive: Keepalive,
      private router: Router, private availablePointsService: AvailablePointsService,
      private contentManagerService: ContentManagerService) {

  }

  ngOnInit() {
  }

  onActivate() {
    switch (this.router.url) {
      case '/rewards/cash_rebate':
      case 'points/account_summary':
      case '/account/profile':
      case '/account/link_accounts':
      case '/points/gift_points':
      case '/rewards/catalog/merch':
      case '/wish_list':
      case '/cart':
      case '/home':
      case '/rewards/catalog/gift_card':
      case '/rewards/egc':
      case '/rewards/local':
      case 'rewards/pay_with_points':
      case '/rewards/catalog/charity':
      case '/rewards/product':
      case '/points/redemption/history':
      case '/points/history': {
        this.availablePointsService.setCartAvailablePoints().then();
        break;
      }
      default: {
        this.themeWrapper.style.setProperty('--color1', '#027EB3');
        this.themeWrapper.style.setProperty('--color2', '#01834F');
        this.themeWrapper.style.setProperty('--color3', '#002d55');
        this.themeWrapper.style.setProperty('--color4', '#000000');
        this.themeWrapper.style.setProperty('--color5', '#ffffff');
        this.themeWrapper.style.setProperty('--color6', '#002d55');
        this.themeWrapper.style.setProperty('--color7', '#000000');
        this.themeWrapper.style.setProperty('--color8', '#027eb3');
        this.themeWrapper.style.setProperty('--color9', '#ffffff');
        break;
      }

    }

    if (localStorage.getItem('token')) {
      const token = localStorage.getItem('token');
      const jwt = this.parseJWT(token);
      const program_code = jwt['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];

      this.contentManagerService.getColor(program_code).toPromise().then(data => {
        localStorage.setItem('cmColors', JSON.stringify(data));
        (data.color1 !== null && data.color1 !== undefined) ? this.themeWrapper.style.setProperty('--color1', data.color1)
          : this.themeWrapper.style.setProperty('--color1', '#027EB3');
          (data.color2 !== null && data.color2 !== undefined) !== null ? this.themeWrapper.style.setProperty('--color2', data.color2)
          : this.themeWrapper.style.setProperty('--color2', '#01834F');
          (data.color3 !== null && data.color3 !== undefined) !== null ? this.themeWrapper.style.setProperty('--color3', data.color3)
          : this.themeWrapper.style.setProperty('--color3', '#002d55');
          (data.color4 !== null && data.color4 !== undefined) !== null ? this.themeWrapper.style.setProperty('--color4', data.color4)
          : this.themeWrapper.style.setProperty('--color4', '#000000');
          (data.color5 !== null && data.color5 !== undefined) !== null ? this.themeWrapper.style.setProperty('--color5', data.color5)
          : this.themeWrapper.style.setProperty('--color5', '#ffffff');
          (data.color6 !== null && data.color6 !== undefined) !== null ? this.themeWrapper.style.setProperty('--color6', data.color6)
          : this.themeWrapper.style.setProperty('--color6', '#002d55');
          (data.color7 !== null && data.color7 !== undefined) !== null ? this.themeWrapper.style.setProperty('--color7', data.color7)
          : this.themeWrapper.style.setProperty('--color7', '#000000');
          (data.color8 !== null && data.color8 !== undefined) !== null ? this.themeWrapper.style.setProperty('--color8', data.color8)
          : this.themeWrapper.style.setProperty('--color8', '#027eb3');
          (data.color9 !== null && data.color9 !== undefined) !== null ? this.themeWrapper.style.setProperty('--color9', data.color9)
          : this.themeWrapper.style.setProperty('--color9', '#ffffff');
      }).catch(() => {
        this.themeWrapper.style.setProperty('--color1', '#027EB3');
        this.themeWrapper.style.setProperty('--color2', '#01834F');
        this.themeWrapper.style.setProperty('--color3', '#002d55');
        this.themeWrapper.style.setProperty('--color4', '#000000');
        this.themeWrapper.style.setProperty('--color5', '#ffffff');
        this.themeWrapper.style.setProperty('--color6', '#002d55');
        this.themeWrapper.style.setProperty('--color7', '#000000');
        this.themeWrapper.style.setProperty('--color8', '#027eb3');
        this.themeWrapper.style.setProperty('--color9', '#ffffff');
      });

    } else {
      this.contentManagerService.getColorDefault().subscribe(data => {
          (data.color1 !== null && data.color1 !== undefined) ? this.themeWrapper.style.setProperty('--color1', data.color1)
          : this.themeWrapper.style.setProperty('--color1', '#027EB3');
          (data.color2 !== null && data.color2 !== undefined) !== null ? this.themeWrapper.style.setProperty('--color2', data.color2)
          : this.themeWrapper.style.setProperty('--color2', '#01834F');
          (data.color3 !== null && data.color3 !== undefined) !== null ? this.themeWrapper.style.setProperty('--color3', data.color3)
          : this.themeWrapper.style.setProperty('--color3', '#002d55');
          (data.color4 !== null && data.color4 !== undefined) !== null ? this.themeWrapper.style.setProperty('--color4', data.color4)
          : this.themeWrapper.style.setProperty('--color4', '#000000');
          (data.color5 !== null && data.color5 !== undefined) !== null ? this.themeWrapper.style.setProperty('--color5', data.color5)
          : this.themeWrapper.style.setProperty('--color5', '#ffffff');
          (data.color6 !== null && data.color6 !== undefined) !== null ? this.themeWrapper.style.setProperty('--color6', data.color6)
          : this.themeWrapper.style.setProperty('--color6', '#002d55');
          (data.color7 !== null && data.color7 !== undefined) !== null ? this.themeWrapper.style.setProperty('--color7', data.color7)
          : this.themeWrapper.style.setProperty('--color7', '#000000');
          (data.color8 !== null && data.color8 !== undefined) !== null ? this.themeWrapper.style.setProperty('--color8', data.color8)
          : this.themeWrapper.style.setProperty('--color8', '#027eb3');
          (data.color9 !== null && data.color9 !== undefined) !== null ? this.themeWrapper.style.setProperty('--color9', data.color9)
          : this.themeWrapper.style.setProperty('--color9', '#ffffff');
      }, () => {
        this.themeWrapper.style.setProperty('--color1', '#027EB3');
        this.themeWrapper.style.setProperty('--color2', '#01834F');
        this.themeWrapper.style.setProperty('--color3', '#002d55');
        this.themeWrapper.style.setProperty('--color4', '#000000');
        this.themeWrapper.style.setProperty('--color5', '#ffffff');
        this.themeWrapper.style.setProperty('--color6', '#002d55');
        this.themeWrapper.style.setProperty('--color7', '#000000');
        this.themeWrapper.style.setProperty('--color8', '#027eb3');
        this.themeWrapper.style.setProperty('--color9', '#ffffff');
      });
    }
  }

  timeout() {
    // sets an idle timeout of 900 seconds, for testing purposes.
    this.idle.setIdle(900);
    // sets a timeout period of 5 seconds. after 60 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      console.log(this.idleState);
    });
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      console.log(this.idleState);
      this.timedOut = true;
    });
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
      console.log(this.idleState);
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => {
      this.lastPing = new Date();
      console.log('this last ping!');
    });

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    console.log('reset');
    this.timedOut = false;
  }

  parseJWT(token: any) {
    token = localStorage.getItem('token');
    const jwtData = token.split('.')[1];
    return JSON.parse(window.atob(jwtData));
  }

  isIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const trident = ua.indexOf('Trident/');

    return (msie > 0 || trident > 0);
  }
}
