import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatDialogRef, MatStepper, MatSnackBarConfig, MatSnackBar } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { AccountService } from 'src/app/services/account.service';
import { map } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { AcceptedTerms } from 'src/app/models/acceptedTerms';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-accepted-terms',
  templateUrl: './accepted-terms.component.html',
  styleUrls: ['./accepted-terms.component.css']
})
export class AcceptedTermsComponent implements OnInit, AfterViewInit {
  imgSrc1 = '../../../assets/images/Rewardslogo.jpg';
  policy = 'https://www.wpcu.coop/about-us/privacy-policies';
  website = 'https://www.WPCU.coop';
  isChecked: false;
  accounts: any;
  balId: any;
  errorText: string;
  loading = false;
  actionButtonLabel = 'Close';
  action = true;

  constructor(private dialogRef: MatDialogRef<AcceptedTermsComponent>, private accountService: AccountService,
    private authService: AuthService, private snackBar: MatSnackBar, private router: Router) {
    }

  ngOnInit() {
    this.router.navigate(['home']);//BW-8142
    this.accountService.GetAccount().subscribe(data => {
      this.accounts = data.accountNumber;
    });
  }
  onChanges(value: any): void {
    this.delay(2000).then(() => {
      // tslint:disable-next-line:radix
    });

  }
  submit(stepper: MatStepper) {
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}
  ngAfterViewInit(): void {
    const content = document.querySelector('.mat-dialog-content');
    const scroll$ = fromEvent(content, 'scroll').pipe(map(() => content));
    scroll$.subscribe(element => {
        if (content.clientHeight + content.scrollTop + 1 > content.scrollHeight) {
            const chk = <HTMLInputElement> document.getElementById('acptTerms');
            chk.disabled = false;
        }
    });
  }
  acceptTerms(e) {
    const chk = <HTMLInputElement> document.getElementById('acptTerms');
        if (chk.checked) {
        const user = localStorage.getItem('userId');
        this.authService.GetAcceptTerms().subscribe(data => {
          if (data != null) {
                if (data[0].accepted === false) {
                  if (user != null) {
                      if (user === 'Online') {
                        const accpt: AcceptedTerms = {
                          id: data[0].id,
                          accepted: true,
                          userId: user,
                          acceptedDate: moment().format()
                        };
                        this.authService.PutAcceptTerms(accpt, data[0].id).subscribe(info => {
                        }, err => {
                          const configError = new MatSnackBarConfig();
                          configError.verticalPosition = 'top';
                          configError.duration = 5000;
                          configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
                          const key = Object.keys(err.error)[0];
                          this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
                            err.error.errors : err.error !== null && err.error !== undefined ?
                            err.error[key][0].length > 1 ? err.error[key][0]
                            : err.error : 'Whoops! Something went wrong',
                          this.action ? this.actionButtonLabel : undefined, configError);
                        });
                      } else {
                        const accpt: AcceptedTerms = {
                          id: data[0].id,
                          accepted: false,
                          userId: user,
                          acceptedDate: moment().format()
                        };
                        this.authService.PutAcceptTerms(accpt, data[0].id).subscribe(info => {
                        }, err => {
                          const configError = new MatSnackBarConfig();
                          configError.verticalPosition = 'top';
                          configError.duration = 5000;
                          configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
                          const key = Object.keys(err.error)[0];
                          this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
                            err.error.errors : err.error !== null && err.error !== undefined ?
                            err.error[key][0].length > 1 ? err.error[key][0]
                            : err.error : 'Whoops! Something went wrong',
                          this.action ? this.actionButtonLabel : undefined, configError);
                        });
                      }
                  } else {
                    const accpt: AcceptedTerms = {
                      id: data[0].id,
                      accepted: true,
                      userId: 'Online',
                      acceptedDate: moment().format()
                    };
                    this.authService.PutAcceptTerms(accpt, data[0].id).subscribe(info => {
                    }, err => {
                      const configError = new MatSnackBarConfig();
                      configError.verticalPosition = 'top';
                      configError.duration = 5000;
                      configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
                      const key = Object.keys(err.error)[0];
                          this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
                            err.error.errors : err.error !== null && err.error !== undefined ?
                            err.error[key][0].length > 1 ? err.error[key][0]
                            : err.error : 'Whoops! Something went wrong',
                          this.action ? this.actionButtonLabel : undefined, configError);
                    });
                  }
              }
               } else {
                if (user != null) {
                    if (user === 'Online') {
                  const accpt: AcceptedTerms = {
                    id: 0,
                    accepted: true,
                    userId: user,
                    acceptedDate: moment().format()
                  };
                  this.authService.PostAcceptTerms(accpt).subscribe(info => {
                  }, err => {
                    const configError = new MatSnackBarConfig();
                    configError.verticalPosition = 'top';
                    configError.duration = 5000;
                    configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
                    const key = Object.keys(err.error)[0];
                          this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
                            err.error.errors : err.error !== null && err.error !== undefined ?
                            err.error[key][0].length > 1 ? err.error[key][0]
                            : err.error : 'Whoops! Something went wrong',
                          this.action ? this.actionButtonLabel : undefined, configError);
                  });
                    } else {
                      const accpt: AcceptedTerms = {
                        id: 0,
                        accepted: false,
                        userId: user,
                        acceptedDate: moment().format()
                      };
                      this.authService.PostAcceptTerms(accpt).subscribe(info => {
                      }, err => {
                        const configError = new MatSnackBarConfig();
                        configError.verticalPosition = 'top';
                        configError.duration = 5000;
                        configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
                        const key = Object.keys(err.error)[0];
                          this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
                            err.error.errors : err.error !== null && err.error !== undefined ?
                            err.error[key][0].length > 1 ? err.error[key][0]
                            : err.error : 'Whoops! Something went wrong',
                          this.action ? this.actionButtonLabel : undefined, configError);
                      });
                        }
                    } else {
                      const accpt: AcceptedTerms = {
                        id: 0,
                        accepted: true,
                        userId: 'Online',
                        acceptedDate: moment().format()
                      };
                      this.authService.PostAcceptTerms(accpt).subscribe(info => {
                      }, err => {
                        const configError = new MatSnackBarConfig();
                        configError.verticalPosition = 'top';
                        configError.duration = 5000;
                        configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
                        const key = Object.keys(err.error)[0];
                          this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
                            err.error.errors : err.error !== null && err.error !== undefined ?
                            err.error[key][0].length > 1 ? err.error[key][0]
                            : err.error : 'Whoops! Something went wrong',
                          this.action ? this.actionButtonLabel : undefined, configError);
                      });
                    }
                }
    });
        localStorage.setItem('isTrue', 'true');
        this.dialogRef.close();
    }
  }
}

