import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig,
  MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Profile } from 'src/app/models/profile';
import { CssService } from 'src/app/services/css.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, AfterViewInit {
  @ViewChild('toolBarBreadcrumb', {static: false}) toolBarBreadcrumb: ElementRef;
  @ViewChild('componentEl', {static: false}) componentEl: ElementRef;
  account: any = {};
  linked_acct_msg = '';
  selectedQuestion = '';
  security_questions: any;
  accountForm: FormGroup;

  displayedColumns: string[] = ['account_no', 'primary_name', 'type'];
  dataSource = new MatTableDataSource();

  actionButtonLabel = 'Close';
  action = true;
  setAutoHide = true;
  autoHide = 3000;
  addExtraClass = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  errorText: string;
  ifLinkedAccts: boolean;
  avail_points: any;
  pending_points: any;
  loading = false;
  profile:  Profile = {};

  constructor(private accountService: AccountService, private authService: AuthService,
     public snackBar: MatSnackBar, private router: Router, private cssService: CssService) { }

  ngOnInit() {
    this.router.navigate(['home']);//BW-8142
    if (localStorage.getItem('expire_pts') !== null && localStorage.getItem('avail_pts') !== null) {
      this.pending_points = +(localStorage.getItem('expire_pts'));
      this.avail_points = localStorage.getItem('avail_pts');
    } else {
      this.accountService.GetPendingPoints().subscribe(data => {
        this.pending_points = data;
      });
      this.accountService.GetAvailablePoints().subscribe(data => {
        this.avail_points = data.availablePoints - this.pending_points;
        this.avail_points = this.format_number(this.avail_points, '', ',', '');
      });
    }
    this.accountForm = new FormGroup({
      sec_questions: new FormControl(''),
      sec_1: new FormControl(''),
      sec_1_conf: new FormControl(''),
      email: new FormControl (''),
      email_conf: new FormControl (''),
      old_password: new FormControl(''),
      password: new FormControl(''),
      password_conf: new FormControl('')
    });
    this.accountService.GetAccount().subscribe(data => {
      // console.log(data);
      if (data.linkedAccount === undefined || data.linkedAccount.length === 0) {
        this.linked_acct_msg = 'You have no linked accounts.';
        this.ifLinkedAccts = false;
      } else {
        this.ifLinkedAccts = true;
        this.linked_acct_msg = 'See your linked accounts below.';
        this.dataSource = data.linkedAccount;
      }
     // this.selectedQuestion = data.securityQuestion;
      this.account = data;

      this.authService.GetSecurityQuestions().subscribe( d => {
        this.security_questions = d;
      });
    });
  }

  ngAfterViewInit(): void {
    this.cssService.styleToolbarIE(this.toolBarBreadcrumb);
    this.cssService.styleTextIE(this.componentEl);
    this.cssService.styleFieldsIE(this.componentEl);
    this.cssService.styleBtnIE(this.componentEl);
  }

  private _createConfig() {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    // config.panelClass = this.addExtraClass ? ['snack-bar'] : undefined;
    return config;
  }
  save() {
    this.loading = true;
    const config = this._createConfig();

    if (!(this.accountForm.value.sec_1 == null || this.accountForm.value.sec_1 === '')) {
      // console.log('question change');
      this.profile.securityQuestion = this.selectedQuestion;
      this.profile.securityAnswer = this.accountForm.value.sec_1;
      this.profile.securityAnswerConfirmation = this.accountForm.value.sec_1_conf;
    } else {
      this.profile.securityQuestion = null;
      this.profile.securityAnswer = null;
      this.profile.securityAnswerConfirmation = null;
    }

    if (!(this.accountForm.value.password == null || this.accountForm.value.password === '')) {
      // console.log('password change');
      this.profile.oldPassword = this.accountForm.value.old_password;
      this.profile.password = this.accountForm.value.password;
      this.profile.passwordConfirmation = this.accountForm.value.password_conf;
    } else {
      this.profile.oldPassword = null;
      this.profile.password = null;
      this.profile.passwordConfirmation = null;
    }

    if (!(this.accountForm.value.email == null || this.accountForm.value.email === '')) {
      // console.log('email change');
      this.profile.email = this.accountForm.value.email;
      this.profile.emailConfirmation = this.accountForm.value.email_conf;
    } else {
      this.profile.email = null;
      this.profile.emailConfirmation = null;
    }
    // console.log(this.profile);
    this.accountService.SaveProfile(this.profile).subscribe(data => {
      this.loading = false;
      this.snackBar.open('Your profile has been saved successfully.', this.action ? this.actionButtonLabel : undefined, config);
      this.account = data;

    }, err => {
      this.loading = false;
      const configError = new MatSnackBarConfig();
      configError.verticalPosition = 'top';
      configError.duration = 5000;
      configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
      const key = Object.keys(err.error)[0];
      this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
        err.error.errors : err.error !== null && err.error !== undefined ?
        err.error[key][0].length > 1 ? err.error[key][0]
        : err.error : 'Whoops! Something went wrong, please try again later',
      this.action ? this.actionButtonLabel : undefined, configError);
    });

    // const account_update = {
    //   securityQuestion: `${this.selectedQuestion}`,
    //   securityAnswer: `${this.accountForm.value.sec_1}`,
    //   securityAnswerConfirmation: `${this.accountForm.value.sec_1_conf}`,
    // };
    // const account_update2 = {
    //   email: `${this.accountForm.value.email}`,
    //   emailConfirmation: `${this.accountForm.value.email_conf}`,
    // };
    // const account_update3 = {
    //   // oldPassword: `${this.accountForm.value.oldPassword}`,
    //   password: `${this.accountForm.value.password}`,
    //   passwordConfirmation: `${this.accountForm.value.password_conf}`
    // };
   // console.log(account_update, account_update2, account_update3);


    // if (this.selectedQuestion != null && this.accountForm.value.sec_1 != null && this.accountForm.value.sec_1_conf != null) {
    //   this.accountService.SaveProfile(account_update).subscribe(data => {
    //     this.snackBar.open('Your profile has been saved successfully.', this.action ? this.actionButtonLabel : undefined, config);
    //     setTimeout(() => {
    //       this.router.navigate(['home']);
    //     } , 4000);
    //   }, error => {
    //     const stringify = JSON.stringify(error.error).replace(/[{}]/g, '');
    //     const x = stringify.replace(/\"/g, '');
    //     this.errorText = x;
    //   });
    // }
    // if (this.accountForm.value.email != null && this.accountForm.value.email_conf != null) {
    //   this.accountService.SaveProfile(account_update2).subscribe(data => {
    //     this.snackBar.open('Your profile has been saved successfully.', this.action ? this.actionButtonLabel : undefined, config);
    //     setTimeout(() => {
    //       this.router.navigate(['home']);
    //     } , 4000);
    //   }, error => {
    //     const stringify = JSON.stringify(error.error).replace(/[{}]/g, '');
    //     const x = stringify.replace(/\"/g, '');
    //     this.errorText = x;
    //   });
    // }
    // if (this.accountForm.value.password != null && this.accountForm.value.passwordConfirmation != null) {
    //   this.accountService.SaveProfile(account_update3).subscribe(data => {
    //     this.snackBar.open('Your profile has been saved successfully.', this.action ? this.actionButtonLabel : undefined, config);
    //     setTimeout(() => {
    //       this.router.navigate(['home']);
    //     } , 4000);
    //   }, error => {
    //     const stringify = JSON.stringify(error.error).replace(/[{}]/g, '');
    //     const x = stringify.replace(/\"/g, '');
    //     this.errorText = x;
    //   });
    // }
  }
  format_number(number: number, prefix, thousand_sep, decimal_sep) {
    if (number < 0) {
      prefix = '-' + prefix;
    }
    const thousand_separator =  thousand_sep || ',';
    const decimal_separator = decimal_sep || '.';
    const regex		= new RegExp('[^' + decimal_separator + '\\d]', 'g');
    const number_string = number.toString().replace(regex, '').toString();
    const split = number_string.split(decimal_separator);
    const rest = split[0].length % 3;
    let result = split[0].substr(0, rest);
    const thousands = split[0].substr(rest).match(/\d{3}/g);

    if (thousands) {
      const separator = rest ? thousand_separator : '';
      result += separator + thousands.join(thousand_separator);
    }
    result = split[1] !== undefined ? result + decimal_separator + split[1] : result;
    return prefix === undefined ? result : (result ? prefix + result : '');
  }
}
