import { CssService } from './../../../services/css.service';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { PointsService } from 'src/app/services/points.service';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-point-summary',
  templateUrl: './point-summary.component.html',
  styleUrls: ['./point-summary.component.css']
})
export class PointSummaryComponent implements OnInit, AfterViewInit {
  @ViewChild('toolBarBreadcrumb', {static: false}) toolBarBreadcrumb: ElementRef;
  @ViewChild('componentEl', {static: false}) componentEl: ElementRef;
  avail_points: any;
  message3: any;
  ifItems: boolean;
  displayedColumns: string[] = ['name', 'cardNo', 'mtd', 'ytd', 'ltd'];
  dataSource = new MatTableDataSource();
  message: string;
  message2: string;
  ifItems2: boolean;
  dataSource2 = new MatTableDataSource();
  ifItems3: boolean;
  dataSource3 = new MatTableDataSource();
  pending_points: any;

  constructor(private accountService: AccountService, private pointsService: PointsService, private cssService: CssService,
    private router: Router) { }

  ngOnInit() {
    this.router.navigate(['home']);//BW-8142
    if (localStorage.getItem('expire_pts') !== null && localStorage.getItem('avail_pts') !== null) {
      this.pending_points = +(localStorage.getItem('expire_pts'));
      this.avail_points = localStorage.getItem('avail_pts');
    } else {
      this.accountService.GetPendingPoints().subscribe(data => {
        this.pending_points = data;
      });
      this.accountService.GetAvailablePoints().subscribe(data => {
        this.avail_points = data.availablePoints - this.pending_points;
        this.avail_points = this.format_number(this.avail_points, '', ',', '');
      });
    }
    this.pointsService.GetPointSummary().subscribe(data => {
      if (data != null) {
        this.dataSource = data;
        this.ifItems = true;
      } else {
        this.message = 'You currently have no point history.';
        this.ifItems = false;
      }
    });
    this.pointsService.GetRedemptiontSummary().subscribe(data => {
      if (data != null) {
        this.dataSource2 = data;
        this.ifItems2 = true;
      } else {
        this.message2 = 'You currently have no redemption history.';
        this.ifItems2 = false;
      }
    });
    this.pointsService.GetTransactionsSummary().subscribe(data => {
      if (data != null) {
        this.dataSource3 = data;
        this.ifItems3 = true;
      } else {
        this.message3 = 'You currently have no transaction history.';
        this.ifItems3 = false;
      }
    });
    // this.pointsService.GetRedemptiontHistory().subscribe(data => {
    // });
  }

  ngAfterViewInit(): void {
    this.cssService.styleToolbarIE(this.toolBarBreadcrumb);
    this.cssService.styleTextIE(this.componentEl);
    this.cssService.styleFieldsIE(this.componentEl);
    this.cssService.styleBtnIE(this.componentEl);
  }

  format_number(number: number, prefix, thousand_sep, decimal_sep) {
    if (number < 0) {
      prefix = '-' + prefix;
    }
    const thousand_separator =  thousand_sep || ',';
    const decimal_separator = decimal_sep || '.';
    const regex		= new RegExp('[^' + decimal_separator + '\\d]', 'g');
    const number_string = number.toString().replace(regex, '').toString();
    const split = number_string.split(decimal_separator);
    const rest = split[0].length % 3;
    let result = split[0].substr(0, rest);
    const thousands = split[0].substr(rest).match(/\d{3}/g);

    if (thousands) {
      const separator = rest ? thousand_separator : '';
      result += separator + thousands.join(thousand_separator);
    }
    result = split[1] !== undefined ? result + decimal_separator + split[1] : result;
    return prefix === undefined ? result : (result ? prefix + result : '');
  }
}
