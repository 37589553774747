import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material';
import { CountdownComponent } from 'ngx-countdown';
import { Idle } from '@ng-idle/core';

@Component({
  selector: 'app-time-out',
  templateUrl: './time-out.component.html',
  styleUrls: ['./time-out.component.css']
})
export class TimeOutComponent implements OnInit {

  @ViewChild('countdown', { static: false }) counter: CountdownComponent;
  token = localStorage.getItem('token');
  jwt = this.parseJWT(this.token);
  program_code = this.jwt['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
  constructor(private router: Router, private dialogRef: MatDialogRef<TimeOutComponent>,
      private idle: Idle) { }

  ngOnInit() {
  }
  onEvent($event): void {
    const timeLeft = $event.left;
    if (timeLeft === 0) {
      this.finishSession();
    }
   }

   finishSession() {
    // console.log('logout');
    if (this.program_code.match('AB')) {
      window.location.href = 'https://www.altabank.com/';
    } else if (this.program_code.match('BSJ')) {
      window.location.href = 'https://www.banksanjuans.com/';
    } else if (this.program_code.match('CCB')) {
      window.location.href = 'https://www.ccb-idaho.com/';
    } else if (this.program_code.match('CPB')) {
      window.location.href = 'https://www.collegiatepeaksbank.com/';
    } else if (this.program_code.match('FBMT')) {
      window.location.href = 'https://www.1stbmt.com/';
    } else if (this.program_code.match('FBWY')) {
      window.location.href = 'https://www.gofirstbank.com/';
    } else if (this.program_code.match('FCBU')) {
      window.location.href = 'https://www.fcbutah.com/';
    } else if (this.program_code.match('FHB')) {
      window.location.href = 'https://www.foothillsbank.com/';
    } else if (this.program_code.match('FSBB')) {
      window.location.href = 'https://www.ourbank.com/';
    } else if (this.program_code.match('FSBM')) {
      window.location.href = 'https://www.fsbmsla.com/';
    } else if (this.program_code.match('FSBWY')) {
      window.location.href = 'https://www.fsbwy.com/';
    } else if (this.program_code.match('GB')) {
      window.location.href = 'https://www.glacierbank.com/';
    } else if (this.program_code.match('HBN')) {
      window.location.href = 'https://www.heritagebanknevada.com/';
    } else if (this.program_code.match('MWB')) {
      window.location.href = 'https://www.mountainwestbank.com/';
    } else if (this.program_code.match('NCB')) {
      window.location.href = 'https://www.northcascadesbank.com/';
    } else if (this.program_code.match('VBG')) {
      window.location.href = 'https://www.valleybankhelena.com/';
    } else if (this.program_code.match('WSB')) {
      window.location.href = 'https://www.westernsecuritybank.com/';
    }
    this.idle.stop();
    localStorage.removeItem('token');
    localStorage.clear();
    this.dialogRef.close();
    this.router.navigate(['']);
    // location.replace(location.origin);
  }

  close() {
    this.dialogRef.close();
  }
  parseJWT(token: any) {
    token = localStorage.getItem('token');
    const jwtData = token.split('.')[1];
    return JSON.parse(window.atob(jwtData));
  }
}
