import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogConfig, MatDialog, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { AcceptedTermsComponent } from '../../account/accepted-terms/accepted-terms.component';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { AvailablePointsService } from 'src/app/services/availablePoints.service';
import { ContentManagerService } from 'src/app/services/content-manager.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { TimeOutComponent } from '../../time-out/time-out.component';

@Component({
  selector: 'app-registration-confirmation',
  templateUrl: './registration-confirmation.component.html',
  styleUrls: ['./registration-confirmation.component.css']
})
export class RegistrationConfirmationComponent implements OnInit {
  decodedJWT: any;
  name: any;
  email: any;
  timedOut = false;
  lastPing?: Date = null;
  loading = false;
  idleState = 'Not started.';
  constructor(
    public dialog: MatDialog, private router: Router, private cd: ChangeDetectorRef,
    private authService: AuthService,
    private availablePointsService: AvailablePointsService,
    private contentManagerService: ContentManagerService,
    private idle: Idle, private keepalive: Keepalive, ) {
    }

  ngOnInit() {
    this.router.navigate(['/']);//BW-8142
    const token = localStorage.getItem('token');
    const jwtData = token.split('.')[1];
    this.decodedJWT = JSON.parse(window.atob(jwtData));
    this.name = this.decodedJWT.unique_name;
    this.email = this.decodedJWT.email;
    if (localStorage.getItem('isTrue')) {
      if ('true' !== localStorage.getItem('isTrue').toString()) {
       this.openDialog();
      }
     } else {
      this.authService.GetAcceptTerms().subscribe(data => {
        if (data != null) {
              if (data[0].accepted === false) {
                this.openDialog();
              }
            } else {
              this.openDialog();
            }
          });
     }
  }
  openDialog() {
    const dialogConfig = new MatDialogConfig();
     dialogConfig.disableClose = true;
     dialogConfig.autoFocus = true;
     dialogConfig.width = '841px';
     const dialogRef = this.dialog.open(AcceptedTermsComponent, dialogConfig);
     dialogRef.afterClosed().subscribe(result => {
      this.cd.detectChanges();
     });
  }
  timeout() {
    // sets an idle timeout of 900 seconds, for testing purposes.
    this.idle.setIdle(900);
    // sets a timeout period of 900 seconds. after 60 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      // console.log(this.idleState);
    });
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      // console.log(this.idleState);
      this.timedOut = true;
    });
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
      this.openDialog();
      // console.log(this.idleState);
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      // console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => {
      this.lastPing = new Date();
      // console.log('this last ping!');
    });

    this.reset();
  }
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    // console.log('reset');
    this.timedOut = false;
  }
  getImages() {
    this.availablePointsService.setCartAvailablePoints()
    .then(() => {
      this.contentManagerService.getBannerImagesPathPromise()
        .catch(() => { return; })
        .then(() => {
          this.contentManagerService.getDocumentsPathPromise()
            .catch(() => { return; })
            .then(() => {
              this.contentManagerService.getLogoImagePathToPromise()
              .catch(() => { return; })
              .then(() => {
                this.contentManagerService.getCppsToPromise()
                  .catch(() =>  { return; })
                  .then(() => {
                    this.contentManagerService.getProgramNameToPromise()
                      .catch(() => { return; })
                      .then(() => {
                        this.router.navigate(['home']);
                        this.loading = false;
                      }, () => {
                        this.router.navigate(['home']);
                        this.loading = false;
                      });
                    });
                });
              });
        });
        this.timeout();
    }, error => {
      this.loading = false;
      const config = new MatSnackBarConfig();
      config.verticalPosition = 'top';
      config.duration = 5000;
      config.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
    });
  }
}
