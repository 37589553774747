import { CssService } from './../../../services/css.service';
import { AvailablePointsService } from 'src/app/services/availablePoints.service';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { RewardsService } from 'src/app/services/rewards.service';
import { AccountService } from 'src/app/services/account.service';
import { Router } from '@angular/router';
import { RedemptionService } from 'src/app/services/redemption.service';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';
import { environment } from 'src/environments/environment';
import { EgcParams } from './../../../models/egc-params';

@Component({
  selector: 'app-wish-list',
  templateUrl: './wish-list.component.html',
  styleUrls: ['./wish-list.component.css']
})
export class WishListComponent implements OnInit, AfterViewInit {
  @ViewChild('toolBarBreadcrumb', {static: false}) toolBarBreadcrumb: ElementRef;
  @ViewChild('componentEl', {static: false}) componentEl: ElementRef;
  avail_points: any;
  token = localStorage.getItem('token');
  jwt = this.parseJWT(this.token);
  program_code = this.jwt['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
  wish_list = [] = [];
  message: string;
  isItems: boolean;
  pending_points: any;
  loading = false;
  actionButtonLabel = 'Close';
  action = true;
  setAutoHide = true;
  autoHide = 3000;
  addExtraClass = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(private accountService: AccountService, private rewardsService: RewardsService, private router: Router,
    public snackBar: MatSnackBar, private redemptionService: RedemptionService,
    private availablePointsService: AvailablePointsService, private cssService: CssService) { }

  ngOnInit() {
    this.router.navigate(['home']);//BW-8142
    if (localStorage.getItem('expire_pts') !== null && localStorage.getItem('avail_pts') !== null) {
      this.pending_points = +(localStorage.getItem('expire_pts'));
      this.avail_points = localStorage.getItem('avail_pts');
    } else {
      this.accountService.GetPendingPoints().subscribe(data => {
        this.pending_points = data;
      });
      this.accountService.GetAvailablePoints().subscribe(data => {
        this.avail_points = data.availablePoints - this.pending_points;
        this.avail_points = this.format_number(this.avail_points, '', ',', '');
      });
    }
    this.rewardsService.GetWishList().subscribe(data => {
      if (data != null) {
        this.wish_list = data;
        this.isItems = true;
      } else {
        this.message = 'You have no items in your wish list.';
        this.isItems = false;
      }
    });
  }
  ngAfterViewInit(): void {
    this.cssService.styleToolbarIE(this.toolBarBreadcrumb);
    this.cssService.styleTextIE(this.componentEl);
    this.cssService.styleFieldsIE(this.componentEl);
    this.cssService.styleBtnIE(this.componentEl);
  }
  removeWishListItem(wl: any) {
    this.loading = true;
    this.rewardsService.DeleteWishListItem(wl).subscribe(data => {
      this.loading = false;
      this.router.navigateByUrl('home', { skipLocationChange: true }).then(() =>
      this.router.navigate(['wish_list']));
    });
  }
  clearWishList() {
    this.loading = true;
    this.rewardsService.ClearWishList().subscribe(data => {
      this.loading = false;
      this.router.navigateByUrl('home', { skipLocationChange: true }).then(() =>
      this.router.navigate(['wish_list']));
    });
  }
  viewDetails(id: any, rewardCode: any, rewardType: any) {
    if (rewardType === 'EC') {
      this.router.navigate([`rewards/product/${rewardCode}`]);
    } else {
    this.router.navigate([`rewards/product/${id}`]);
    }
  }

  addToCart(id: any, wlId: any, rewardCode: any, rewardType: any) {
    this.loading = true;
    const config = this._createConfig();
    let redemption;
    if (rewardType === 'EC') {
      const params = new EgcParams();
      params.clientId = environment.client_id;
      params.programCode = this.program_code;
      params.rewardCategory = 'ESVS';
      params.rewardCode = rewardCode;
      params.rewardType = rewardType;
      this.rewardsService.GetProductEc(params).subscribe(data => {
        redemption = {
          rewardId: data.id,
          quantity: 1,
          denomination: data.cost,
          pointsRequired: data.points,
          type: data.type,
          code: data.rewardCode
        };
        const availablePoints = this.avail_points.replace(/,/g, '');
      if (availablePoints >= (redemption.pointsRequired * redemption.quantity)) {
        this.redemptionService.AddtoCart(redemption).subscribe(() => {
          this.loading = false;
          this.availablePointsService.setCartAvailablePoints()
            .then(result => {
              this.removeWishListItem(wlId);
              this.loading = false;
              this.avail_points = result.availablePoints;
              this.snackBar.open('Item has been added to your cart.', '', config);
              this.router.navigateByUrl('home', { skipLocationChange: true }).then(() =>
              this.router.navigate(['wish_list']));
            });
        }, err => {
            this.loading = false;
            const configError = new MatSnackBarConfig();
            configError.verticalPosition = 'top';
            configError.duration = 5000;
            configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
            const key = Object.keys(err.error)[0];
            this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
              err.error.errors : err.error !== null && err.error !== undefined ?
              err.error[key][0].length > 1 ? err.error[key][0]
              : err.error : 'Whoops! Something went wrong',
            this.action ? this.actionButtonLabel : undefined, configError);
        });
      } else {
        this.loading = false;
            const configError = new MatSnackBarConfig();
            configError.verticalPosition = 'top';
            configError.duration = 5000;
            configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];


            this.snackBar.open('You don\'t have enough points to redeem this item',
              this.action ? this.actionButtonLabel : undefined, configError);
      }
      });
    } else {
    this.rewardsService.GetProductById(id).subscribe(data => {
       redemption = {
        rewardId: data.id,
        quantity: 1,
        denomination: data.cost,
        pointsRequired: data.points,
        type: data.type,
        code: data.code
      };

      const availablePoints = this.avail_points.replace(/,/g, '');
      if(data.rewardCode.indexOf('GBGC') > -1 && redemption.denomination * redemption.quantity < 100) {
        this.loading = false;
        const configError = new MatSnackBarConfig();
        configError.verticalPosition = 'top';
        configError.duration = 5000;
        configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
        this.snackBar.open('Minimum amount required for Visa prepaid reward card is $100.',
          this.action ? this.actionButtonLabel : undefined, configError);
    } else {
      if (availablePoints >= (redemption.pointsRequired * redemption.quantity)) {
        this.redemptionService.AddtoCart(redemption).subscribe(() => {
          this.loading = false;
          this.availablePointsService.setCartAvailablePoints()
            .then(result => {
              this.removeWishListItem(wlId);
              this.loading = false;
              this.avail_points = result.availablePoints;
              this.snackBar.open('Item has been added to your cart.', '', config);
              this.router.navigateByUrl('home', { skipLocationChange: true }).then(() =>
              this.router.navigate(['wish_list']));
            });
        }, err => {
            this.loading = false;
            const configError = new MatSnackBarConfig();
            configError.verticalPosition = 'top';
            configError.duration = 5000;
            configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
            const key = Object.keys(err.error)[0];
            this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
              err.error.errors : err.error !== null && err.error !== undefined ?
              err.error[key][0].length > 1 ? err.error[key][0]
              : err.error : 'Whoops! Something went wrong',
            this.action ? this.actionButtonLabel : undefined, configError);
        });
      } else {
        this.loading = false;
            const configError = new MatSnackBarConfig();
            configError.verticalPosition = 'top';
            configError.duration = 5000;
            configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];


            this.snackBar.open('You don\'t have enough points to redeem this item',
              this.action ? this.actionButtonLabel : undefined, configError);
      }
     } 
    });
  }
  }

  private _createConfig() {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    return config;
  }

  format_number(number: number, prefix, thousand_sep, decimal_sep) {
    if (number < 0) {
      prefix = '-' + prefix;
    }
    const thousand_separator =  thousand_sep || ',';
    const decimal_separator = decimal_sep || '.';
    const regex		= new RegExp('[^' + decimal_separator + '\\d]', 'g');
    const number_string = number.toString().replace(regex, '').toString();
    const split = number_string.split(decimal_separator);
    const rest = split[0].length % 3;
    let result = split[0].substr(0, rest);
    const thousands = split[0].substr(rest).match(/\d{3}/g);

    if (thousands) {
      const separator = rest ? thousand_separator : '';
      result += separator + thousands.join(thousand_separator);
    }
    result = split[1] !== undefined ? result + decimal_separator + split[1] : result;
    return prefix === undefined ? result : (result ? prefix + result : '');
  }
  parseJWT(token: any) {
    token = localStorage.getItem('token');
    const jwtData = token.split('.')[1];
    return JSON.parse(window.atob(jwtData));
  }
}
