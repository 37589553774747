// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  /* LOCAL CONFIG */

  //baseApiUrl: 'http://localhost:16624/api/',
  //baseCors: 'http://localhost:4200/',
  //montroseUrl: 'https://testsaml2.bwloyalty.com/MontroseSSO.aspx?SessionID=',
  //contentManagerAPI: 'https://contentmanager.bwloyalty.com/api/',

  /* END LOCAL CONFIG */

  // contentManagerAPITest: 'https://contentmanager.bwloyalty.com/api/',

  /* PROD CONFIG */

   baseApiUrl: 'https://redeembankrewards.com/api/',
   baseCors: 'https://redeembankrewards.com/',
   montroseUrl: 'https://saml2.breakawayloyalty.com/MontroseSSO.aspx?SessionID=',
   hindmerchUrl: 'https://hinda.breakawayloyalty.com/DefaultSSO.aspx?sso=',
   contentManagerAPI: 'https://contentmanager.breakawayloyalty.com/api/',
   client_id: '202001',
   azigoEndpoint: 'https://gep.partnersmall.com/sso?hash=',
   azigoSSOKey: 'o25626604d86cd8fcf4ff37d5370f32c',
   production: true
  /* END PROD CONFIG */

  /* TEST CONFIG */
  // baseApiUrl: 'https://glacier.bwloyalty.com/api/',
  // baseCors: 'https://glacier.bwloyalty.com/',
  // montroseUrl: 'https://testsaml2.bwloyalty.com/MontroseSSO.aspx?SessionID=',
  // contentManagerAPI: 'https://contentmanager.bwloyalty.com/api/',
  // hindmerchUrl: 'https://hinda.bwloyalty.com/DefaultSSO.aspx?sso=',
  // client_id: '202001',
  // azigoEndpoint: 'https://gep.partnersmall.com/sso?hash=',
  // azigoSSOKey: 'o25626604d86cd8fcf4ff37d5370f32c',
  // production: true
   /* END TEST CONFIG */
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
