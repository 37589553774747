import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  toke: any;
  jwt: any;
  program_code: any;
  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log("Intercepted Request");

    const token = localStorage.getItem('token');

    // console.log(`token: ${token}`);
    if (token != null) {
      const authReq = req.clone({
        headers: req.headers
          .set('Authorization', `Bearer ${token}`)
          .set('X-REQUEST-INFO', window.location.href.toString())
          .set('Access-Control-Allow-Origin', `*`)
          .set('Access-Control-Allow-Methods', 'GET, POST, PUT, PATCH')
          .set('Access-Control-Allow-Headers', 'Content-Type, X-Auth - Token, Origin, Authorization')
          // .set("Access-Control-Request-Headers", "access-control-allow-methods, access-control-allow-origin, content-type")

      });

      if (req.url.includes('upload')) {
        if (!req.headers.has('Content-Type')) {
          req = req.clone({ headers: req.headers.delete('Content-Type') });
        }
        req = req.clone({ headers: req.headers.set('Content-Type', 'multipart/form-data')});
      } else {
        authReq.headers.append('content-type', 'application/json');
      }
      // console.log("Authorization header added");
      return next.handle(authReq)
        .pipe(tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {

            // do stuff with response if you want
          }
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              // redirect to the login route
              // or show a modal
              this.handle401Error();
            }
          }
        }));
      //     .do((err: any) =>{
      //     if(err instanceof HttpErrorResponse){
      //         if(err.status === 401){
      //             localStorage.removeItem('auth_app_token');
      //             this.router.navigate(['auth/login']);
      //         }
      //     }
      // });
    } else {
      // console.log("No JWT Available");
      const nonAuthReq = req.clone({
        headers: req.headers
          .set('Access-Control-Allow-Origin', `${environment.baseCors}`)
          .set('Access-Control-Allow-Methods', 'GET, POST, PUT, PATCH')
          .set('X-REQUEST-INFO', window.location.href.toString())
        });
      return next.handle(nonAuthReq);
    }
  }

  handle401Error() {
    this.toke = localStorage.getItem('token');
    if (this.toke != null || this.toke !== undefined) {
    this.jwt = this.parseJWT(this.toke);
    this.program_code = this.jwt['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
    if (this.program_code.match('AB')) {
      window.location.href = 'https://www.altabank.com/';
    } else if (this.program_code.match('BSJ')) {
      window.location.href = 'https://www.banksanjuans.com/';
    } else if (this.program_code.match('CCB')) {
      window.location.href = 'https://www.ccb-idaho.com/';
    } else if (this.program_code.match('CPB')) {
      window.location.href = 'https://www.collegiatepeaksbank.com/';
    } else if (this.program_code.match('FBMT')) {
      window.location.href = 'https://www.1stbmt.com/';
    } else if (this.program_code.match('FBWY')) {
      window.location.href = 'https://www.gofirstbank.com/';
    } else if (this.program_code.match('FCBU')) {
      window.location.href = 'https://www.fcbutah.com/';
    } else if (this.program_code.match('FHB')) {
      window.location.href = 'https://www.foothillsbank.com/';
    } else if (this.program_code.match('FSBB')) {
      window.location.href = 'https://www.ourbank.com/';
    } else if (this.program_code.match('FSBM')) {
      window.location.href = 'https://www.fsbmsla.com/';
    } else if (this.program_code.match('FSBWY')) {
      window.location.href = 'https://www.fsbwy.com/';
    } else if (this.program_code.match('GB')) {
      window.location.href = 'https://www.glacierbank.com/';
    } else if (this.program_code.match('HBN')) {
      window.location.href = 'https://www.heritagebanknevada.com/';
    } else if (this.program_code.match('MWB')) {
      window.location.href = 'https://www.mountainwestbank.com/';
    } else if (this.program_code.match('NCB')) {
      window.location.href = 'https://www.northcascadesbank.com/';
    } else if (this.program_code.match('VBG')) {
      window.location.href = 'https://www.valleybankhelena.com/';
    } else if (this.program_code.match('WSB')) {
      window.location.href = 'https://www.westernsecuritybank.com/';
    }
  }
    localStorage.removeItem('token');
    this.router.navigate(['']);
  }
  parseJWT(token: any) {
    token = localStorage.getItem('token');
    const jwtData = token.split('.')[1];
    return JSON.parse(window.atob(jwtData));
  }
}

// setHeaders: {
//     Authorization: `Bearer ${localStorage.get('auth_app_token')}`
// }
