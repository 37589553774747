import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material';
import { Idle } from '@ng-idle/core';
import { environment } from 'src/environments/environment';
import { RedemptionService } from 'src/app/services/redemption.service';
@Component({
  selector: 'app-hinda-confirmation',
  templateUrl: './hinda-confirmation.component.html',
  styleUrls: ['./hinda-confirmation.component.css']
})
export class HindaConfirmationComponent implements OnInit {

  guidHinda: any;

  constructor(private router: Router, private redemptionService: RedemptionService, private dialogRef: MatDialogRef<HindaConfirmationComponent>,
      private idle: Idle) { }

  ngOnInit() {
    this.router.navigate(['home']);//BW-8142
  }
  continueMerch() {
    this.redemptionService.GetHindaGuid().subscribe(data => {
      this.guidHinda = data;
      window.location.href = `${environment.hindmerchUrl}${this.guidHinda}`;
    });
  }
  viewCart() {
    this.router.navigate(["cart"]);
    this.dialogRef.close();
  }

}
