import { Component } from '@angular/core';

@Component({
  selector: 'app-header-logged-out',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  imgSrc = '../../../assets/images/Rewardslogo.jpg';
}
