import { CssService } from './../../../services/css.service';
import { Idle } from '@ng-idle/core';
import { AvailablePointsService } from 'src/app/services/availablePoints.service';
import { AccountService } from 'src/app/services/account.service';
import { PaginationService } from './../../../services/pagination.service';
import { Component, OnInit, Renderer2, ElementRef, ViewChild, isDevMode, AfterViewInit, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { RewardsService } from 'src/app/services/rewards.service';
import { RedemptionService } from 'src/app/services/redemption.service';
import { CatalogParams } from 'src/app/models/catalog-params';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Product } from 'src/app/models/product';
import { PageEvent, MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition } from '@angular/material';
import { environment } from 'src/environments/environment';
import { ContentManagerService } from 'src/app/services/content-manager.service';
import { DomSanitizer } from '@angular/platform-browser';
declare  const azigoSSO:  any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class Header2Component implements OnInit, AfterViewInit {
  @ViewChild('cmNav', {static: false}) el: ElementRef;
  isAccount = false;
  isCart = false;
  isWL = false;
  isSearch = false;
  wish_list_count: any;
  cart_count: any;
  guid: any;
  decodedJWT: any;
  bal_id: any;
  travel_link: string;
  searchValue: string;
  @ViewChild('sideSearch', { read: ElementRef, static: true }) sideSearch: ElementRef;
  token = localStorage.getItem('token');
  jwt = this.parseJWT(this.token);
  program_code = this.jwt['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
  pagedList: Product[] = [];
  quantity_form: FormGroup;
  img_url = [] = [];
  formArr = [];
  actionButtonLabel = 'Close';
  action = true;
  setAutoHide = true;
  autoHide = 3000;
  addExtraClass = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  avail_points: any;
  pending_points: any;
  isDemo = false;
  programName = '';
  isLogo = false;
  showName = false;
  logo = this.sanitizer.bypassSecurityTrustResourceUrl(localStorage.getItem('logo'));
  constructor(
    private router: Router,
    private rewardsService: RewardsService,
    private redemptionService: RedemptionService,
    private paginationService: PaginationService,
    public snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private availablePointsService: AvailablePointsService,
    private contentManagerService: ContentManagerService,
    private sanitizer: DomSanitizer,
    private idle: Idle,
    private cssService: CssService) {
      this.paginationService.paginationModel.currentPage = 1;
  }

  ngOnInit() {
    this.contentManagerService.getProgramName(this.program_code).subscribe(data => {
      this.programName = data.programName;
      this.isLogo = data.isLogo;
      this.showName = data.showName;
    });
    this.redemptionService.DeleteShipping().subscribe(data => {
      this.availablePointsService.setCartAvailablePoints()
        .then(result => {
          this.avail_points = result.availablePoints;
        });
    });
    if (localStorage.getItem('expire_pts') !== null && localStorage.getItem('avail_pts') !== null) {
      this.pending_points = +(localStorage.getItem('expire_pts'));
      this.avail_points = localStorage.getItem('avail_pts');
    } else {
      this.accountService.GetPendingPoints().subscribe(data => {
        this.pending_points = data;
      });
      this.accountService.GetAvailablePoints().subscribe(data => {
        this.avail_points = data.availablePoints - this.pending_points;
        this.avail_points = this.format_number(this.avail_points, '', ',', '');
      });
    }
    this.rewardsService.GetWishList().subscribe(data => {
      if (data != null) {
        this.wish_list_count = data.length;
      } else {
        this.wish_list_count = 0;
      }
    });
    this.redemptionService.GetCartItems().subscribe(data => {
      if (data != null) {
        this.cart_count = data.length;
      } else {
        this.cart_count = 0;
      }
    });
    this.redemptionService.GetTravelGuid().subscribe(data => {
      this.guid = data;
      this.travel_link = `${environment.montroseUrl}${this.guid}`;
    });

    this.contentManagerService.isDemo().subscribe(data => {
      this.isDemo = data;
    });

    const token = localStorage.getItem('token');
    const jwtData = token.split('.')[1];
    this.decodedJWT = JSON.parse(window.atob(jwtData));
    // this.name = this.decodedJWT.unique_name;
    this.bal_id = this.decodedJWT.given_name;
  }

  ngAfterViewInit() {
    this.cssService.setHeaderStyle(this.el);
  }

  search(searchTerm: any) {
    this.searchValue = searchTerm.toString();
    this.getRewards(this.searchValue);
    console.log('pagedList: ');
    console.log(this.pagedList);
  }
  showAccountOptions() {
    this.isAccount = !this.isAccount;
    this.isCart = false;
    this.isSearch = false;
    this.isWL = false;
  }
  showWLOptions() {
    this.isAccount = false;
    this.isCart = false;
    this.isSearch = false;
    this.isWL = !this.isWL;
  }
  showCartOptions() {
    this.isAccount = false;
    this.isCart = !this.isCart;
    this.isSearch = false;
    this.isWL = false;
  }
  showSearchOptions() {
    this.isAccount = false;
    this.isCart = false;
    this.isSearch = !this.isSearch;
    this.isWL = false;
  }
  logout() {
    if (this.program_code.match('AB')) {
      window.location.href = 'https://www.altabank.com/';
    } else if (this.program_code.match('BSJ')) {
      window.location.href = 'https://www.banksanjuans.com/';
    } else if (this.program_code.match('CCB')) {
      window.location.href = 'https://www.ccb-idaho.com/';
    } else if (this.program_code.match('CPB')) {
      window.location.href = 'https://www.collegiatepeaksbank.com/';
    } else if (this.program_code.match('FBMT')) {
      window.location.href = 'https://www.1stbmt.com/';
    } else if (this.program_code.match('FBWY')) {
      window.location.href = 'https://www.gofirstbank.com/';
    } else if (this.program_code.match('FCBU')) {
      window.location.href = 'https://www.fcbutah.com/';
    } else if (this.program_code.match('FHB')) {
      window.location.href = 'https://www.foothillsbank.com/';
    } else if (this.program_code.match('FSBB')) {
      window.location.href = 'https://www.ourbank.com/';
    } else if (this.program_code.match('FSBM')) {
      window.location.href = 'https://www.fsbmsla.com/';
    } else if (this.program_code.match('FSBWY')) {
      window.location.href = 'https://www.fsbwy.com/';
    } else if (this.program_code.match('GB')) {
      window.location.href = 'https://www.glacierbank.com/';
    } else if (this.program_code.match('HBN')) {
      window.location.href = 'https://www.heritagebanknevada.com/';
    } else if (this.program_code.match('MWB')) {
      window.location.href = 'https://www.mountainwestbank.com/';
    } else if (this.program_code.match('NCB')) {
      window.location.href = 'https://www.northcascadesbank.com/';
    } else if (this.program_code.match('VBG')) {
      window.location.href = 'https://www.valleybankhelena.com/';
    } else if (this.program_code.match('WSB')) {
      window.location.href = 'https://www.westernsecuritybank.com/';
    }
    this.idle.stop();
    localStorage.clear();
    this.router.navigate(['']);
  }

  openSearch() {
    this.sideSearch.nativeElement.style.width = '100%';
  }
  closeNav() {
    this.searchValue = null;
    this.sideSearch.nativeElement.style.width = '0';
  }

  getRewards(search?: string) {
    const params = new CatalogParams();
    // params.category = category;
    this.formBuilder = new FormBuilder();
    params.search = search;
    this.rewardsService.getSearchRewards(this.program_code, params).subscribe(result => {
      if (result.body !== null) {
        this.pagedList = result.body;

        this.paginationService.paginationModel.currentPage = JSON.parse(result.headers.get('Pagination')).currentPage;
        this.paginationService.paginationModel.itemsPerPage = JSON.parse(result.headers.get('Pagination')).itemsPerPage;
        this.paginationService.paginationModel.totalPages = JSON.parse(result.headers.get('Pagination')).totalPages;
        this.paginationService.paginationModel.totalItems = JSON.parse(result.headers.get('Pagination')).totalItems;

        // this.pagedList = data.slice(0, 10);
        // this.length = this.productsList.length;
        this.img_url = [];
        for (let i = 0; i < this.pagedList.length; i++) {
          this.img_url.push(this.GetImageURL(this.pagedList[i].rewardCode, '150'));
          this.pagedList[i].imgUrl = this.img_url[i];
        }
        this.formArr = [];
        this.pagedList.forEach((x) => {
          if (x.type === 'GC') {
            this.img_url.push(this.GetImageURL(x.denominations[0].code, '150'));
            x.imgUrl = this.GetImageURL(x.denominations[0].code, '150');
            this.formArr.push(this.formBuilder.group({
              quantity: this.formBuilder.control(1, [Validators.required]),
              denom: this.formBuilder.control('', [Validators.required])
            }));
          } else {
            this.img_url.push(this.GetImageURL(x.rewardCode, '150'));
            x.imgUrl = this.GetImageURL(x.rewardCode, '150');
            this.formArr.push(this.formBuilder.group({
              quantity: this.formBuilder.control(1, [Validators.required])
            }));
          }
        });
      }
    });
    // this.quantity_form = new FormGroup({
    //   quantity: new FormControl(1, [Validators.required])
    // });
  }
  GetImageURL(reward_code: any, size: any) {
    if (isDevMode()) {
      // let useSize = '150';
      // useSize = size;
      // return '../../../assets/images/RewardImages/150/' + reward_code + '.jpg';
    }

    return `${environment.baseCors}RewardImages/150/${reward_code}.jpg`.replace('www.', '');
  }

  parseJWT(token: any) {
    token = localStorage.getItem('token');
    const jwtData = token.split('.')[1];
    return JSON.parse(window.atob(jwtData));
  }
  onPaginatorChange(event: PageEvent) {
    this.paginationService.change(event);
    window.scroll(0, 0);
      this.getRewards(this.searchValue);
  }

  private _createConfig() {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    return config;
  }

  CartAddGC(formValues: any) {
    const config = this._createConfig();

    this.rewardsService.GetProductById(formValues.denom)
      .subscribe(data => {

        const redemption = {
          rewardId: data.id,
          quantity: formValues.quantity,
          denomination: data.cost,
          pointsRequired: data.points,
          type: data.type,
          code: data.code
        };
        const availablePoints = this.avail_points.replace(/,/g, '');
        if (availablePoints >= (redemption.pointsRequired * redemption.quantity)) {
          this.redemptionService.AddtoCart(redemption).subscribe(() => {
            this.availablePointsService.setCartAvailablePoints()
              .then(result => {
                this.avail_points = result.availablePoints;
                this.snackBar.open('Item has been added to your cart.', '', config);
                this.router.navigateByUrl('home', { skipLocationChange: true }).then(() =>
                this.router.navigate(['cart']));
              });
          }, err => {
              const configError = new MatSnackBarConfig();
              configError.verticalPosition = 'top';
              configError.duration = 5000;
              configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
              const key = Object.keys(err.error)[0];
              this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
                err.error.errors : err.error !== null && err.error !== undefined ?
                err.error[key][0].length > 1 ? err.error[key][0]
                : err.error : 'Whoops! Something went wrong',
              this.action ? this.actionButtonLabel : undefined, configError);
          });
        } else {
          const configError = new MatSnackBarConfig();
          configError.verticalPosition = 'top';
          configError.duration = 5000;
          configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];

          this.snackBar.open('You don\'t have enough points to redeem this item',
          this.action ? this.actionButtonLabel : undefined, configError);
        }
      });
  }
  WishListAddGC(product: any, formValues: any) {
    const config = this._createConfig();
    this.rewardsService.GetProductById(formValues.denom)
      .subscribe(data => {
        const wish_list = {
          rewardCode: data.rewardCode,
          quantity: formValues.quantity,
          rewardType: product.type
        };
        this.rewardsService.AddtoWishList(wish_list).subscribe(() => {
          this.snackBar.open('Item has been added to your wish list.', '', config);
          this.router.navigateByUrl('home', { skipLocationChange: true }).then(() =>
          this.router.navigate(['wish_list']));
        }, err => {
            const configError = new MatSnackBarConfig();
            configError.verticalPosition = 'top';
            configError.duration = 5000;
            configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
            const key = Object.keys(err.error)[0];
            this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
              err.error.errors : err.error !== null && err.error !== undefined ?
              err.error[key][0].length > 1 ? err.error[key][0]
              : err.error : 'Whoops! Something went wrong',
            this.action ? this.actionButtonLabel : undefined, configError);
        });
      });
  }

  viewDetailsGC(formValues: any) {
    const id = formValues.denom;
    console.log(id);
    this.router.navigate([`rewards/product/${id}`]);
  }
  viewDetailsMerch(id: any) {
    this.router.navigate([`rewards/product/${id}`]);
  }

  CartAddMerch(product: any, productId: number, formValues: any) {
    const config = this._createConfig();
    // let index = this.productsList.findIndex(i => i.id === product.id);
    const redemption = {
      rewardId: productId,
      quantity: formValues.quantity,
      pointsRequired: product.points,
      type: product.type,
      code: product.code
    };
    const availablePoints = this.avail_points.replace(/,/g, '');
    if (availablePoints >= (redemption.pointsRequired * redemption.quantity)) {
      this.redemptionService.AddtoCart(redemption).subscribe(() => {
        this.availablePointsService.setCartAvailablePoints()
          .then(result => {
            this.avail_points = result.availablePoints;
            this.snackBar.open('Item has been added to your cart.', '', config);
            this.router.navigateByUrl('home', { skipLocationChange: true }).then(() =>
            this.router.navigate(['cart']));
          });
      }, err => {
        const configError = new MatSnackBarConfig();
        configError.verticalPosition = 'top';
        configError.duration = 5000;
        configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
        const key = Object.keys(err.error)[0];
        this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
          err.error.errors : err.error !== null && err.error !== undefined ?
          err.error[key][0].length > 1 ? err.error[key][0]
          : err.error : 'Whoops! Something went wrong',
        this.action ? this.actionButtonLabel : undefined, configError);
      });
    } else {
      const configError = new MatSnackBarConfig();
      configError.verticalPosition = 'top';
      configError.duration = 5000;
      configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];

      this.snackBar.open('You don\'t have enough points to redeem this item',
      this.action ? this.actionButtonLabel : undefined, configError);
    }
  }
  WishListAddMerch(rewardCode: any, rewardType: any, formValues: any) {
    const config = this._createConfig();
    const wish_list = {
      rewardCode: rewardCode,
      quantity: formValues.quantity,
      rewardType: rewardType
    };
    this.rewardsService.AddtoWishList(wish_list).subscribe(() => {
      this.snackBar.open('Item has been added to your wish list.', '', config);
      this.router.navigateByUrl('home', { skipLocationChange: true }).then(() =>
      this.router.navigate(['wish_list']));
  }, err => {
    const configError = new MatSnackBarConfig();
    configError.verticalPosition = 'top';
    configError.duration = 5000;
    configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
    const key = Object.keys(err.error)[0];
    this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
      err.error.errors : err.error !== null && err.error !== undefined ?
      err.error[key][0].length > 1 ? err.error[key][0]
      : err.error : 'Whoops! Something went wrong',
    this.action ? this.actionButtonLabel : undefined, configError);
  });
  }

  format_number(number: number, prefix, thousand_sep, decimal_sep) {
    if (number < 0) {
      prefix = '-' + prefix;
    }
    const thousand_separator =  thousand_sep || ',';
    const decimal_separator = decimal_sep || '.';
    const regex		= new RegExp('[^' + decimal_separator + '\\d]', 'g');
    const number_string = number.toString().replace(regex, '').toString();
    const split = number_string.split(decimal_separator);
    const rest = split[0].length % 3;
    let result = split[0].substr(0, rest);
    const thousands = split[0].substr(rest).match(/\d{3}/g);

    if (thousands) {
      const separator = rest ? thousand_separator : '';
      result += separator + thousands.join(thousand_separator);
    }
    result = split[1] !== undefined ? result + decimal_separator + split[1] : result;
    return prefix === undefined ? result : (result ? prefix + result : '');
  }
  foo(b: any) {
    const _b = !!b;
  }

  isIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const trident = ua.indexOf('Trident/');

    return (msie > 0 || trident > 0);
  }
}
